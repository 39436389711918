/* eslint-disable */
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import GoogleMapContainer from '../../../../../containers/main/GoogleMapContainer'
import PropTypes from 'prop-types'
import {
  GOOGLE_MAP_CONSTANTS,
  getSimulationMarkerIcon,
} from '../../../../../constants/googleMapConstants'
import { calculateCenter } from '../../../../../utils/googleMapUtils'
import MarkerTextInfo from '../../../../../components/GoogleMap/MarkerTextInfo'
import SelectedOrdersRecap from './selectedOrdersRecap'
import { ACCURATE_ADDRESS } from '../../../../../utils/values'
const SimulateMap = ({
  tours,
  focusedTours,
  unservedTours,
  mapContainerStyle,
  mapId,
  removeSelectedCourses,
  addSelectedCourses,
  selectedCourses,
  removeAllSelectedCourses,
  isOptimisationCoursesFiles,
  tourRecChecked,
}) => {
  const [markers, setMarkers] = useState([])
  const [isInitialized, setIsInitialized] = useState(false)
  const [mapOptions, setMapOption] = useState({
    center: {
      lat: GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lat,
      lng: GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lng,
    },
    zoom: 10,
  })
  const [unselectTrigger, setUnselectTrigger] = useState(false)
  const getVisitMarkers = () => {
    const markersv = tours.flatMap((e, i) => {
      return e.markers.map((marker) => {
        return {
          ...marker,
          driver: e.driver.firstName,
          index: i,
        }
      })
    })
    const unservedMarkers = unservedTours
      .filter(
        (e) => e.endLocation.lat != undefined || e.endLocation.lng != undefined
      )
      .map((course) => {
        return {
          position: {
            lat: course.endLocation.lat,
            lng: course.endLocation.lng,
          },
          color:
            isOptimisationCoursesFiles &&
            !ACCURATE_ADDRESS.includes(course.locationType)
              ? '#f61057'
              : selectedCourses?.includes(course.id)
              ? '#1976D2'
              : '#595959',
          // #595959
          codeCourse: course.id,
          infoText: (
            <MarkerTextInfo
              orderGiver={course.orderGiver}
              ldv={course.ldv || course.id}
              locationID={course.ldv}
              locationName={course.endLocation.name}
              courseType={course.courseType}
              reason={course.reason}
              weight={course.weight}
              windowStart={course.dropOffStart}
              windowEnd={course.dropOffEnd}
              numFacture={isOptimisationCoursesFiles ? course.id : null}
            />
          ),
          isFocused: isOptimisationCoursesFiles
            ? ACCURATE_ADDRESS.includes(course.locationType)
            : true,
          ticket: course.courseType == 'Reprise' ? 'R' : 'L',
          icon: getSimulationMarkerIcon(),
        }
      })
    //console.log('testing unserved tours marker', unservedMarkers)

    if (markersv.length !== 0) {
      const servedMarkers = markersv
        .map(
          ({
            location_id,
            ldv,
            location_name,
            arrival_time,
            lat,
            lng,
            driver,
            finish_time,
            type,
            index,
            color,
            courseType,
            courseRank,
            weight,
            windowEnd,
            windowStart,
            client,
          }) => {
            if (lat && lng && type === 'pickup') {
              return {
                position: {
                  lat: lat,
                  lng: lng,
                },
                color: color,
                codeCourse: location_id,
                isFocused: false,
                infoText: (
                  <MarkerTextInfo
                    driver={driver}
                    courseType={courseType}
                    numFacture={location_id}
                    locationID={ldv}
                    locationName={location_name}
                    type={type}
                  />
                ),
                ticket: ' ',
                icon: getSimulationMarkerIcon(),
                index: index,
              }
            }
            // We filter all the markers and we let only type drop off
            if (lat && lng && type === 'dropoff') {
              return {
                position: {
                  lat: lat,
                  lng: lng,
                },
                color: tourRecChecked?.includes(location_id) ? '#1976D2' : color,
                codeCourse: location_id,
                isFocused: false,
                infoText: (
                  <MarkerTextInfo
                    driver={driver}
                    courseType={courseType}
                    arrival={arrival_time}
                    locationID={ldv}
                    locationName={location_name}
                    departure={finish_time}
                    type={type}
                    weight={weight}
                    windowStart={windowStart}
                    windowEnd={windowEnd}
                    client={client}
                    numFacture={location_id}
                  />
                ),
                ticket: courseRank ? courseRank.toString() : 'P',
                icon: getSimulationMarkerIcon(),
                index: index,
              }
            }
          }
        )
        .filter((marker) => marker)
      return [...servedMarkers, ...unservedMarkers]
    }
  }
  // Use memo to avoid recalculating poly unless tours change
  const poly = useMemo(
    () =>
      tours
        .map((tour, i) =>
          tour.markers.length > 1
            ? { path: tour.path, index: i, color: tour.color }
            : undefined
        )
        .filter(Boolean),
    [tours]
  )

  useEffect(() => {
    if (tours.length > 0) {
      setMarkers(getVisitMarkers())
    }
  }, [tours, unservedTours])

  useEffect(() => {
    if (markers.length > 0 && !isInitialized) {
      initMarkers(8)
      setIsInitialized(true)
    }
  }, [markers, isInitialized])

  const initMarkers = useCallback(
    (zoom) => {
      setMapOption(calculateCenter(markers, zoom, 0))
    },
    [markers]
  )

  const removeAllSelected = () => {
    setUnselectTrigger(true)
    removeAllSelectedCourses()
  }

  useEffect(() => {
    if (
      tours[focusedTours[0]] &&
      tours[focusedTours[0]]?.markers?.length > 2 &&
      selectedCourses.length == 0
    ) {
      setMapOption(calculateCenter(tours[focusedTours[0]]?.markers, 9, 0, true))
    }
  }, [tours])

  return (
    <>
      <GoogleMapContainer
        mapContainerStyle={mapContainerStyle}
        mapId={mapId}
        mapOptions={mapOptions}
        setMapOption={setMapOption}
        markers={markers}
        isDrawable={true}
        polyLines={poly}
        removeSelectedCourses={removeSelectedCourses}
        addSelectedCourses={addSelectedCourses}
        selectedCourses={selectedCourses}
        setUnselectTrigger={setUnselectTrigger}
        unselectTrigger={unselectTrigger}
        removeAllSelected={removeAllSelected}
      />
      <SelectedOrdersRecap
        unservedTours={unservedTours}
        selectedCourses={selectedCourses}
        isOptimisationCoursesFiles={isOptimisationCoursesFiles}
      />
    </>
  )
}

SimulateMap.propTypes = {
  tours: PropTypes.any,
  focusedTours: PropTypes.array,
  unservedTours: PropTypes.any,
  mapContainerStyle: PropTypes.any,
  mapId: PropTypes.any,
  isOptimisationCoursesFiles: PropTypes.bool,
  tourRecChecked: PropTypes.array,
}
export default SimulateMap
