//TODO LINT
/* eslint-disable */
import { getDays, getHours } from './dateAdapter'
import moment from 'moment'
const cornerUrl = process.env.REACT_APP_CORNER_URL
import {
  ClientDelivery,
  ClientPickup,
  ROUTIFIC_ERROR_ENUM,
  tourType,
} from './values'
const colorArray = [
  '#2979ff',
  '#ff1744',
  '#76ff03',
  '#ff00ff',
  '#ff6d00',
  '#ffff00',
  '#00e5ff',
  '#ff5252',
  '#00c853',
  '#aa00ff',
  '#ff9100',
  '#ffd600',
  '#304ffe',
  '#d50000',
  '#b2ff59',
  '#e040fb',
  '#ffab40',
  '#ffff00',
  '#00b8d4',
  '#d500f9',
  '#6200ea',
]
const getAncienPositionElement = (tab, listChecked, pos) => {
  const checkedElements = listChecked.map((item) => JSON.stringify(item))

  for (let i = pos + 1; i < tab.length; i++) {
    const current = tab[i]

    if (
      current?.type != 'depotEnd' &&
      !checkedElements.includes(JSON.stringify(current))
    ) {
      return current
    }
  }

  return null
}
const getNewPosition = (tab, listChecked, pos, newTab) => {
  if (pos <= 1) {
    return 0
  } else if (pos == tab.length - 2) {
    return newTab.length
  } else {
    const element = getAncienPositionElement(tab, listChecked, pos)
    if (element == null) {
      return newTab.length
    } else {
      for (let i = 0; i < newTab.length; i++) {
        const current = newTab[i]

        if (JSON.stringify(element) == JSON.stringify(current)) {
          return pos < listChecked[0].order ? i - 1 : i
        }
      }
    }
  }
}
const reorderMultiple = (
  list,
  tourRecChecked,
  startIndex,
  endIndex,
  isOnePickUp
) => {
  let result = Array.from(list)
  let course = result[startIndex]
  if (!Object.keys(tourRecChecked).includes(result[startIndex].receptionNumber)) {
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
  } else {
    const listChecked = result.filter(
      (rslt) =>
        Object.keys(tourRecChecked).includes(rslt.receptionNumber) &&
        ((tourRecChecked[rslt.receptionNumber]?.dropoff == true &&
          rslt.type == 'dropoff') ||
          (tourRecChecked[rslt.receptionNumber]?.pickup == true &&
            rslt.type == 'pickup'))
    )
    const debut = result[0]
    const fin = result[result.length - 1]

    let middle = result
      .slice(1, -1)
      .filter(
        (rslt) =>
          !(
            Object.keys(tourRecChecked).includes(rslt.receptionNumber) &&
            ((tourRecChecked[rslt.receptionNumber]?.dropoff == true &&
              rslt.type == 'dropoff') ||
              (tourRecChecked[rslt.receptionNumber]?.pickup == true &&
                rslt.type == 'pickup'))
          )
      )

    middle.splice(
      getNewPosition([...result], listChecked, endIndex, [...middle]),
      0,
      ...listChecked.sort((a, b) => a.order - b.order)
    )

    result = [debut, ...middle, fin]
  }
  let validator = true
  if (isOnePickUp === tourType.ONE_PICK_UP) {
    const newResult = [...result]
    validator = validateOrderCheck(newResult)
    result = sortMarkers(result, course)
  }
  return { newMarkerList: result, validator: validator }
}

const elementExist = (tab1, element) => {
  return tab1.filter((el) => el.location_id == element.location_id).length
}

const dragMultipleBetweenTables = (
  sourceList,
  destList,
  tourRecChecked,
  startIndex,
  endIndex,
  isOnePickUp
) => {
  let source = Array.from(sourceList)
  let destination = Array.from(destList)
  let course = source[startIndex]

  const listChecked = source.filter(
    (rslt) =>
      Object.keys(tourRecChecked).includes(rslt.receptionNumber) &&
      ((tourRecChecked[rslt.receptionNumber]?.dropoff == true &&
        rslt.type == 'dropoff') ||
        (tourRecChecked[rslt.receptionNumber]?.pickup == true &&
          rslt.type == 'pickup'))
  )
  let indexEnd =
    endIndex > 1
      ? endIndex - listChecked.length + 1 < 1
        ? endIndex
        : endIndex - listChecked.length + 1
      : 1

  const listCheckedTwin = listChecked

  // suprimmer twins dans tableau source
  listChecked.forEach((list) => {
    source.map((ele, pos) => {
      if (list.location_id === ele.location_id && list.type === ele.type) {
        source.splice(pos, 1)
      }
    })
  })

  // trouver twins dans tableau source
  source.forEach((el) => {
    if (elementExist(listChecked, el) == 1) {
      listCheckedTwin.push(el)
    }
  })

  // suprimmer twins dans tableau source
  listCheckedTwin.forEach((list) => {
    source.map((ele, pos) => {
      if (list.location_id === ele.location_id && list.type === ele.type) {
        source.splice(pos, 1)
      }
    })
  })

  //ajout les nouveaux elements dans tableau destination
  listCheckedTwin
    .sort((a, b) => a.order - b.order)
    .map((Visit) => {
      destination.splice(indexEnd, 0, Visit)
      indexEnd++
    })

  if (isOnePickUp === tourType.ONE_PICK_UP) {
    destination = sortMarkers(destination, course)
  }
  return { source, destination }
}
export const getToursData = (responseData, deliveryDate, driverTours) => {
  const driversWithColors = []
  const tours = responseData.map((response) => {
    return response.tours.map((tour) => {
      if (!(tour.markers.length === 2 || tour.markers.length === 1)) {
        driversWithColors.push(tour.driver.id)
      }
      const driver = {
        id: tour.driver.id,
        firstName: tour.driver.firstName,
        name: tour.driver.name,
        competences: tour.driver.competences,
        vehiculeType: tour.driver.vehiculeType ?? '-',
        vehiculeWeight: tour.driver.vehiculeWeight,
        shiftStart: tour.driver.shiftStart.label
          ? tour.driver.shiftStart.label
          : tour.driver.shiftStart
          ? tour.driver.shiftStart
          : undefined,
        shiftEnd: tour.driver.shiftEnd.label
          ? tour.driver.shiftEnd.label
          : tour.driver.shiftEnd
          ? tour.driver.shiftEnd
          : undefined,
      }
      const markers = tour.markers.map((marker) => {
        return {
          ...marker,
          arrival_time: getHours(
            marker.arrival_time ? marker.arrival_time : marker.estimatedArrivalTime
          ),
          finish_time: getHours(
            marker.finish_time ? marker.finish_time : marker.estimatedDepartureTime
          ),
          day: getDays(
            marker.arrival_time ? marker.arrival_time : marker.estimatedArrivalTime
          ),
          estimatedArrivalTime: moment(
            marker.arrival_time ? marker.arrival_time : marker.estimatedArrivalTime
          ).format(),
          estimatedDepartureTime: moment(
            marker.finish_time ? marker.finish_time : marker.estimatedDepartureTime
          ).format(),
          windowEnd: marker.windowEnd ? marker.windowEnd : undefined,
          windowStart: marker.windowStart ? marker.windowStart : undefined,
          secondWindowStart: marker.secondWindowStart
            ? marker.secondWindowStart
            : undefined,
          secondWindowEnd: marker.secondWindowEnd
            ? marker.secondWindowEnd
            : undefined,
          idle_time: marker.idle_time ? marker.idle_time : undefined,
          isManu: marker.isManu ? marker.isManu : undefined,
          courseType: marker?.courseType ? marker?.courseType : undefined,
          salesChanel: marker?.salesChanel,
          keys: marker?.keys,
          nbColis: marker?.nbColis,
          prestaTime: marker?.prestaTime,
          receptionNumber: marker?.location_id,
          codePostale: marker?.codePostale,
          locationType: marker?.locationType,
          orderGiver: marker?.orderGiver,
          client: marker?.client,
          reason: marker?.reason,
          city: marker?.city ? marker?.city : undefined,
        }
      })
      // driverTours [{ driverId: s.driver.id, ldt: s.ldt }]
      if (driver.id && driverTours && driverTours.length > 0) {
        tour.ldt = driverTours.find((pred) => pred.driverId == driver.id)
          ? driverTours.find((pred) => pred.driverId == driver.id).ldt
          : undefined
      }
      return {
        ...tour,
        color:
          driversWithColors.indexOf(tour.driver) !== -1
            ? colorArray[driversWithColors.indexOf(tour.driver) % colorArray.length]
            : '#000000',
        driver,
        distance: tour.distance,
        totalWeight: tour.tourWeight,
        tourDropoffWeight: tour?.tourDropoffWeight || 0,
        tourPickupWeight: tour?.tourPickupWeight || 0,
        totalVolume: tour.tourVolume,
        totalTime: tour.tourDuration,
        montantPrestataireHT: tour.tourPrice,
        trajetTime: tour.tourTravel,
        stopsNumber: tour?.stopsNumber || 0,
        markers,
        deliveryDate: deliveryDate,
        lock: tour?.lock || false,
      }
    })
  })
  console.log('testing after parse', tours)
  return tours
}

// parse updated tours to send to polaris MS recalculate API
export const parseUpdatedTours = (updatedTours, isOnePickUp) => {
  return updatedTours.map((t) => ({
    driver: t.driver,
    lock: t.lock,
    markers: fixFirstMarker(t.markers),
    tourType: isOnePickUp,
  }))
}

const fixFirstMarker = (markers) => {
  markers.map((m) => {
    m.arrival_time = m.etaArrival
    m.finish_time = m.etaFinish
  })

  return markers
}
// re-order tours
export const reorderTours = (result, prevTours, tourRecChecked, isOnePickUp) => {
  if (result) {
    let changedTours = []
    const newTours = [...prevTours]
    let validatorCheck = true
    //drag and drop in the same table
    if (result.destination.droppableId === result.source.droppableId) {
      const { newMarkerList, validator } = reorderMultiple(
        prevTours[parseInt(result.destination.droppableId)].markers,
        tourRecChecked,
        result.source.index,
        result.destination.index,
        isOnePickUp === true ? tourType.ONE_PICK_UP : tourType.MULTI_PICK_UP
      )
      validatorCheck = validator
      newMarkerList.map((item, i) => {
        item.order = i
      })
      newTours[parseInt(result.destination.droppableId)].markers = newMarkerList
      changedTours.push(newTours[parseInt(result.destination.droppableId)])
    }
    //drag and drop between tables
    else {
      const { source, destination } = dragMultipleBetweenTables(
        prevTours[parseInt(result.source.droppableId)].markers,
        prevTours[parseInt(result.destination.droppableId)].markers,
        tourRecChecked,
        result.source.index,
        result.destination.index,
        isOnePickUp === true ? tourType.ONE_PICK_UP : tourType.MULTI_PICK_UP
      )
      newTours[parseInt(result.source.droppableId)].markers = source.map(
        (item, i) => {
          item.order = i
          return item
        }
      )
      newTours[parseInt(result.destination.droppableId)].markers = destination.map(
        (item, i) => {
          item.order = i
          return item
        }
      )
      changedTours.push(
        newTours[parseInt(result.source.droppableId)],
        newTours[parseInt(result.destination.droppableId)]
      )
    }
    //console.log('testing prev', prevTours)
    return { simTours: changedTours, validator: validatorCheck }
  }
}
export const reorderFromUnserved = (
  result,
  prevTours,
  tourRecChecked,
  unservedTours,
  selectedCourses,
  handleCheck,
  isOnePickUp
) => {
  if (result) {
    const newTours = [...prevTours]
    const changedTours = []

    const { newRresult, newUnservedTours, validator } = insertUnserved(
      prevTours[
        parseInt(
          result.destination.droppableId === '-1'
            ? result.source.droppableId
            : result.destination.droppableId
        )
      ].markers,
      tourRecChecked,
      unservedTours,
      selectedCourses,
      handleCheck,
      result.source.index,
      result.destination.index,
      result.destination.droppableId === '-1' ? true : false,
      isOnePickUp === true ? tourType.ONE_PICK_UP : tourType.MULTI_PICK_UP
    )
    newTours[
      parseInt(
        result.destination.droppableId === '-1'
          ? result.source.droppableId
          : result.destination.droppableId
      )
    ].markers = newRresult.map((item, i) => {
      item.order = i
      return item
    })
    unservedTours = newUnservedTours

    changedTours.push(
      newTours[
        parseInt(
          result.destination.droppableId === '-1'
            ? result.source.droppableId
            : result.destination.droppableId
        )
      ]
    )

    return {
      simTours: changedTours,
      isUnservedChanged: result.destination.droppableId === '-1' ? true : false,
      newUnservedTours: unservedTours,
      validator,
    }
  }
  return true
}

const insertUnserved = (
  list,
  tourRecChecked,
  unservedTours,
  selectedCourses,
  handleCheck,
  startIndex,
  endIndex,
  inverted,
  isOnePickUp
) => {
  let result = Array.from(list)

  //chauffeur to course
  if (inverted) {
    const listChecked = result.filter(
      (rslt) =>
        Object.keys(tourRecChecked).includes(rslt.receptionNumber) &&
        ((tourRecChecked[rslt.receptionNumber]?.dropoff == true &&
          rslt.type == 'dropoff') ||
          (tourRecChecked[rslt.receptionNumber]?.pickup == true &&
            rslt.type == 'pickup'))
    )
    // suprimmer twins dans tableau source
    listChecked.forEach((list) => {
      result.forEach((ele, pos) => {
        if (list.location_id === ele.location_id && list.type === ele.type) {
          result.splice(pos, 1)
        }
      })
    })

    // trouver twins dans tableau source
    const listCheckedTwin = listChecked
    result.forEach((el) => {
      if (elementExist(listChecked, el) == 1) {
        listCheckedTwin.push(el)
      }
    })

    // suprimmer twins dans tableau source
    listCheckedTwin.forEach((list) => {
      result.forEach((ele, pos) => {
        if (list.location_id === ele.location_id && list.type === ele.type) {
          result.splice(pos, 1)
        }
      })
    })
    let unservedToursPlus = {}
    listCheckedTwin.forEach((el) => {
      unservedToursPlus[el.location_id] = {}
    })
    listCheckedTwin.forEach((el) => {
      el.alert = 700
      el.reason = 'course placée manuellement'
      if (el.type === 'dropoff') {
        unservedToursPlus[el.location_id].dropOffVisit = el
        unservedToursPlus[el.location_id].dropOffVisit.type = '-'
      } else if (el.type === 'pickup') {
        unservedToursPlus[el.location_id].pickUpVisit = el
        unservedToursPlus[el.location_id].pickUpVisit.type = '-'
      }
    })

    unservedTours = [
      ...unservedTours,
      ...parseCourseFromTourneeToUnserved(unservedToursPlus),
    ]
    //course to chauffeur
  } else {
    // check validation before remove unservedCourses in one pickup
    if (
      !validateLocationOnePickUp(
        result,
        unservedTours,
        selectedCourses,
        isOnePickUp === tourType.ONE_PICK_UP
      )
    ) {
      return {
        newRresult: result,
        newUnservedTours: unservedTours,
        validator: false,
      }
    }

    let course = unservedTours[startIndex]

    getCheckedUnserved(unservedTours, selectedCourses).forEach((course, i) => {
      handleCheck(course.id)
      result.splice(endIndex, 0, { ...getPickUpVisitFromUnserved(course) })
      result.splice(endIndex + 1, 0, { ...getDropOffVisitFromUnserved(course) })
      unservedTours = deleteFromUnserved(unservedTours, [course.id])
    })
    if (isOnePickUp === tourType.ONE_PICK_UP) {
      result = sortMarkers(result, course)
    }
  }
  return {
    newRresult: result,
    newUnservedTours: unservedTours,
    validator: true,
  }
}

export const getPlanningData = (responseData, deliveryDate) => {
  const driversWithColors = []

  const tours = responseData.map((tour) => {
    if (!(tour.visits.length === 2 || tour.visits.length === 1)) {
      driversWithColors.push(tour.driver.id)
    }
    const path = [tour.path]
    const driver = {
      id: tour.driver.id,
      firstName: tour.driver.firstName,
      name: tour.driver.name,
      vehiculeType: tour.driver.vehicule?.vehiculeType ?? '-',
      competences: tour.driver.competences,
      weight: tour.driver.weight,
    }
    const tourType = tour.tourType

    let courseRank = 0

    const newMarkers = tour.visits.map((visit) => {
      courseRank = courseRank + (visit.type === 'dropoff' ? 1 : 0)
      return {
        ...visit,
        arrival_time: getHours(
          visit.arrival_time ? visit.arrival_time : visit.estimatedArrivalTime
        ),
        finish_time: getHours(
          visit.finish_time ? visit.finish_time : visit.estimatedDepartureTime
        ),
        day: getDays(
          visit.arrival_time ? visit.arrival_time : visit.estimatedArrivalTime
        ),
        estimatedArrivalTime: moment(
          visit.arrival_time ? visit.arrival_time : visit.estimatedArrivalTime
        ).format(),
        estimatedDepartureTime: moment(
          visit.finish_time ? visit.finish_time : visit.estimatedDepartureTime
        ).format(),
        windowEnd: visit.windowEnd ? visit.windowEnd : undefined,
        windowStart: visit.windowStart ? visit.windowStart : undefined,
        secondWindowEnd: visit.secondWindowEnd ? visit.secondWindowEnd : undefined,
        secondWindowStart: visit.secondWindowStart
          ? visit.secondWindowStart
          : undefined,
        idleTime: visit.idleTime ? visit.idleTime : undefined,
        isManu: visit.isManu ? visit.isManu : undefined,
        montantPrestaHT: visit?.course?.montantPrestaHT,
        courseType: visit?.course?.courseType ? visit.course.courseType : undefined,
        salesChanel: visit?.course?.salesChanel,
        courseRank: visit.type === 'dropoff' ? courseRank : undefined,
      }
    })
    const markers = appendMarkersObject(newMarkers)
    return {
      ...tour,
      driver,
      color:
        driversWithColors.indexOf(tour.driver.id) !== -1
          ? colorArray[driversWithColors.indexOf(tour.driver.id) % colorArray.length]
          : '#000000',
      distance: tour.distance,
      totalWeight: tour.tourWeight,
      tourDropoffWeight: tour?.tourDropoffWeight || 0,
      tourPickupWeight: tour?.tourPickupWeight || 0,
      totalVolume: tour.tourVolume,
      totalTime: tour.tourDuration,
      trajetTime: tour.tourTravel,
      stopsNumber: tour?.stopsNumber || 0,
      tourType,
      montantPrestataireHT: tour.tourPrice,
      markers,
      path,
      deliveryDate: deliveryDate,
      lock: false,
    }
  })
  return tours
}

export const appendMarkersObject = (mark) => {
  const markersArray = mark.map((visit) => {
    return {
      lat: visit?.cachedAddress?.lat,
      lng: visit?.cachedAddress?.lng,
      location_name: visit?.cachedAddress?.locationName,
      identifier: parseInt(visit?.cachedAddress?.code),
      location_id: visit?.course?.codeCourse,
      weight: visit?.course?.weight >= 0 ? visit?.course?.weight : 0,
      volume: visit?.course?.volume >= 0 ? visit?.course?.volume : 0,
      montantPrestaHT: visit?.montantPrestaHT,
      day: visit?.day,
      estimatedArrivalTime: visit?.estimatedArrivalTime,
      estimatedDepartureTime: visit?.estimatedDepartureTime,
      distance: visit?.distance,
      arrival_time: visit?.arrival_time,
      finish_time: visit?.finish_time,
      createdAt: visit.createdAt,
      order: visit?.rank,
      type: visit?.type,
      windowEnd: visit?.windowEnd,
      windowStart: visit?.windowStart,
      secondWindowEnd: visit.secondWindowEnd,
      secondWindowStart: visit.secondWindowStart,
      isManu: visit?.isManu,
      ldv: visit?.ldv ? visit?.ldv : undefined,
      courseRank: visit.courseRank ? visit.courseRank : undefined,
      courseType: visit?.courseType ? visit?.courseType : undefined,
      salesChanel: visit?.salesChanel,
      keys: visit?.keys,
      nbColis: visit?.nbColis,
      prestaTime: visit?.prestaTime,
      receptionNumber: visit?.location_id,
      codePostale: visit?.codePostale,
      locationType: visit?.locationType,
      orderGiver: visit?.orderGiver,
      client: visit?.client,
      reason: visit?.reason,
      lateBy: visit?.lateBy,
      late_by: visit?.late_by,
      idleTime: visit?.idleTime,
      too_late: visit?.too_late,
      slotOverRun: visit?.slotOverRun,
      rejectedKeys: visit?.rejectedKeys,
      city: visit?.city,
      locationType: visit?.locationType,
      nbColis: visit?.nbColis,
    }
  })
  return markersArray
}

export const fillEmptyDriverPlanning = (fleets, deliveryDate) => {
  const fleetArray = fleets.map((fleet) => {
    const plan = {
      lock: false,
      color: '#000000',
      totalVolume: 0,
      totalWeight: 0,
      tourDropoffWeight: 0,
      tourPickupWeight: 0,
      totalTime: 0,
      montantPrestataireHT: 0,
      trajetTime: 0,
      stopsNumber: 0,
      driver: {
        id: String(fleet.id),
        firstName: fleet.firstName,
        name: fleet.name,
        shiftStart: fleet.shiftStart.label,
        shiftEnd: fleet.shiftEnd.label,
        vehiculeType: fleet.vehiculeType,
        competences: fleet.competences,
        vehiculeWeight: fleet.weight,
      },
      //id: String(fleet.driver.id),
      distance: 0,
      deliveryDate,
      path: [''],
      markers: [
        {
          location_id: `${fleet.firstName}_start`,
          location_name: fleet.startLocation.name,
          arrival_time: fleet.shiftStart.label,
          finish_time: fleet.shiftStart.label,
          distance: 0,
          order: 0,
          type: 'depotStart',
          lat: fleet.startLocation.lat,
          lng: fleet.startLocation.lng,
          identifier: fleet.startLocation.identifier,
        },
        {
          location_id: `${fleet.firstName}_end`,
          location_name: fleet.endLocation.name,
          arrival_time: fleet.shiftEnd.label,
          finish_time: fleet.shiftEnd.label,
          distance: 0,
          order: 1,
          type: 'depotEnd',
          lat: fleet.endLocation.lat,
          lng: fleet.endLocation.lng,
          identifier: fleet.endLocation.identifier,
        },
      ],
    }

    return plan
  })
  return fleetArray
}

const addShiftStartPlanning = (plannings, fleets) => {
  return plannings.map((plan) => {
    const mappedFleetPlanning = fleets.find((fleet) => fleet.id == plan.driver.id)
    return {
      ...plan,
      driver: {
        ...plan.driver,
        shiftStart: mappedFleetPlanning
          ? mappedFleetPlanning.shiftStart.label
          : undefined,
        shiftEnd: mappedFleetPlanning
          ? mappedFleetPlanning.shiftEnd.label
          : undefined,
      },
    }
  })
}

export const newPlannings = (plannings, fleets, deliveryDate) => {
  const newPlanning = addShiftStartPlanning(plannings, fleets).flat(9)
  const emptyDriversPlannigns = fillEmptyDriverPlanning(fleets, deliveryDate)
  emptyDriversPlannigns.forEach((item) => {
    if (!plannings.find((plan) => plan.driver.id == item.driver.id)) {
      newPlanning.push(item)
    }
  })
  return newPlanning
}

export const planningEmpty = (planning) => {
  return planning.every((obj) => obj.markers.length === 2)
}

export const locationCheck = (
  unservedTours,
  selectedCourses,
  planning,
  isOnePickUp
) => {
  const checkIdentifierEqual = unservedTours
    .filter((obj) => selectedCourses.includes(obj.id))
    .map((course) => {
      if (ClientPickup.includes(course.courseType)) {
        return course.endLocation.identifier
      } else {
        return course.startLocation.identifier
      }
    })
    .every((v, i, arr) => v == arr[0])
  return !checkIdentifierEqual && planningEmpty(planning) && !isOnePickUp
}

export const getFleetParse = (fleet, courses, isOnePickUp) => {
  if (isOnePickUp) {
    const fleetDriverArray = { ...fleet }
    const unservedCoursesArray = courses.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    )
    // set driver start and end to the same deprature address if its VRP system
    const identifier = parseInt(unservedCoursesArray.map((tour) => tour.identifier))
    const lat = parseFloat(unservedCoursesArray.map((tour) => tour.lat))
    const lng = parseFloat(unservedCoursesArray.map((tour) => tour.lng))
    const name = unservedCoursesArray.map((tour) => tour.name).toString()

    fleetDriverArray.drivers = fleetDriverArray.drivers.map((driver) => {
      return {
        ...driver,
        startLocation: {
          identifier: identifier,
          lat: lat,
          lng: lng,
          name: name,
        },
        endLocation: {
          identifier: identifier,
          lat: lat,
          lng: lng,
          name: name,
        },
      }
    })
    return fleetDriverArray
  } else {
    return fleet
  }
}

export const getRoutificMessageError = (helperCode, helperVar) => {
  if (
    helperCode == ROUTIFIC_ERROR_ENUM.ERR_INVALID_LAT_LNG_NETWORK.code ||
    helperCode == ROUTIFIC_ERROR_ENUM.ERR_UNKNOWN.code ||
    helperCode == ROUTIFIC_ERROR_ENUM.ERR_MISSING_DRIVER_LAT_LNG.code ||
    helperCode == ROUTIFIC_ERROR_ENUM.ERR_MULTIPLE_LOCATION_COURSES.code ||
    helperCode == ROUTIFIC_ERROR_ENUM.ERR_OUT_OF_INTERVALL_COURSES.code
  ) {
    return { message: ROUTIFIC_ERROR_ENUM[helperCode].message }
  } else {
    return {
      message: ROUTIFIC_ERROR_ENUM[helperCode].message.concat(
        ', Merci de modifier cette course via : '
      ),
      editUrl: `${cornerUrl}edit/${helperVar}`,
    }
  }
}

// getting tourType from existing tours
export const getTourTypeFromPlanning = (planning) => {
  const tourTypeArray = planning.map((i) => i.tourType).filter((obj) => obj)
  // check if tours have the same tourType
  if (tourTypeArray.every((obj) => obj === tourType.ONE_PICK_UP)) {
    return tourType.ONE_PICK_UP
  } else if (tourTypeArray.every((obj) => obj === tourType.MULTI_PICK_UP)) {
    return tourType.MULTI_PICK_UP
  }
}

// if planning is empty set tourType to initiale state, otherwise set tourType to existing value
export const existingPlanning = (planning, isOnePickUp) => {
  const planningLength = planning.map((i) => i.markers.length).some((obj) => obj > 2)
  if (planningLength) {
    const tourTypeFromPlanning = getTourTypeFromPlanning(planning)
    return tourTypeFromPlanning === tourType.ONE_PICK_UP
  }
  return !isOnePickUp
}

// validate order
const validateOrderCheck = (newResult) => {
  const markerType = newResult
    .filter((obj) => obj.type == 'dropoff' || obj.type == 'pickup')
    .map((marker) => {
      return marker.type
    })
  // sort markers and verify if it's ordred as markers from payload or no
  return markerType.every((type, i, arr) =>
    i < markerType.length / 2 - 1 ? type === arr[i + 1] : true
  )
}

// get identifier From exsiting planning
const locationFromPlanning = (result) => {
  return result
    .filter(
      (obj) =>
        (obj.type == 'dropoff' || obj.type == 'pickup') &&
        ((!ClientDelivery.includes(obj.courseType) &&
          !ClientPickup.includes(obj.courseType) &&
          obj.type === 'pickup') ||
          (ClientDelivery.includes(obj.courseType) && obj.type === 'pickup') ||
          (ClientPickup.includes(obj.courseType) && obj.type === 'dropoff'))
    )
    .map((marker) => {
      return marker.identifier
    })
}

// validate identifier
export const validateLocationOnePickUp = (
  result,
  unservedTours,
  selectedCourses,
  isOnePickUp
) => {
  // if driver have a planning comparing unservedCourses and planning identifiers
  if (isOnePickUp) {
    const identifierFromUnserved = unservedTours
      .filter((obj) => selectedCourses.includes(obj.id))
      .map((course) => {
        if (ClientPickup.includes(course.courseType)) {
          return course.endLocation.identifier
        } else {
          return course.startLocation.identifier
        }
      })
      .filter((obj) => obj)
    const identifier = [...locationFromPlanning(result), ...identifierFromUnserved]
    return identifier.every((v, i, arr) => v == arr[0])
  }
  return true
}

// sort markers (pickup reverse dropoff)
const sortMarkers = (result, course) => {
  const pickupsMarkers = result.filter((obj) => obj.type === 'pickup')
  const dropoffMarkers = result.filter((obj) => obj.type === 'dropoff')
  let newResult
  if (course.type === 'pickup') {
    const pickupId = pickupsMarkers.map(({ location_id }) => location_id)
    dropoffMarkers.sort(
      (a, b) => pickupId.indexOf(a.location_id) - pickupId.indexOf(b.location_id)
    )
    newResult = [...dropoffMarkers, ...pickupsMarkers.reverse()]
  } else {
    const dropoffId = dropoffMarkers.map(({ location_id }) => location_id)
    pickupsMarkers.sort(
      (a, b) => dropoffId.indexOf(a.location_id) - dropoffId.indexOf(b.location_id)
    )
    newResult = [...dropoffMarkers.reverse(), ...pickupsMarkers]
  }
  result.splice(1, result.length - 2)
  for (const marker of newResult) {
    result.splice(1, 0, marker)
  }
  return result
}

export const reorderFromUnservedDeattribute = (
  positionsChauffeur,
  prevTours,
  unservedTours
) => {
  const { newRresult, newUnservedTours } = insertUnservedDesattrib(
    positionsChauffeur,
    prevTours,
    unservedTours
  )

  return {
    simTours: newRresult,
    newUnservedTours: newUnservedTours,
  }
}

const insertUnservedDesattrib = (positionsChauffeur, tournees, unservedTours) => {
  const changedTours = []
  let unservedToursPlus = {}

  positionsChauffeur.forEach((position) => {
    let result = tournees[position].markers
    const courses = result.slice(1, result.length - 1)
    courses.forEach((el) => {
      unservedToursPlus[el.location_id] = {}
    })

    courses.forEach((el) => {
      el.alert = 700
      el.reason = 'course placée manuellement'
      if (el.type === 'dropoff') {
        unservedToursPlus[el.location_id].dropOffVisit = el
      } else if (el.type === 'pickup') {
        unservedToursPlus[el.location_id].pickUpVisit = el
      }
    })
    tournees[position].markers = [result[0], result[result.length - 1]].map(
      (item, i) => {
        item.order = i
        return item
      }
    )
    changedTours.push(tournees[position])
  })

  return {
    newRresult: changedTours,
    newUnservedTours: [
      ...unservedTours,
      ...parseCourseFromTourneeToUnserved(unservedToursPlus),
    ],
  }
}

export const fleetParseObject = (driversOriginal, deliveryDate) => {
  const drivers = []
  driversOriginal.forEach(
    ({
      id,
      firstname,
      lastname,
      adresseDepart,
      adresseArrivee,
      operationalHours,
      vehiculeId,
      competences,
    }) =>
      drivers.push({
        firstName: firstname,
        name: lastname,
        id: String(id),
        startLocation: {
          name: adresseDepart.address,
          lat: adresseDepart.latitude,
          lng: adresseDepart.longitude,
          identifier: adresseDepart.id,
        },
        endLocation: {
          name: adresseArrivee.address,
          lat: adresseArrivee.latitude,
          lng: adresseArrivee.longitude,
          identifier: adresseArrivee.id,
        },
        shiftStart: {
          hour: operationalHours.openTime.split(':')[0],
          minute: operationalHours.openTime.split(':')[1],
          label: operationalHours.openTime,
        },
        shiftEnd: {
          hour: operationalHours.closeTime.split(':')[0],
          minute: operationalHours.closeTime.split(':')[1],
          label: operationalHours.closeTime,
        },
        volume: vehiculeId?.vehicule_category?.volumeMax || null,
        weight: vehiculeId?.vehicule_category?.weight || null,
        vehiculeType: vehiculeId?.vehicule_category?.type || '-',
        competences: competences,
        breakStart: 0,
        breakEnd: 0,
      })
  )
  return {
    drivers,
    maxVisitLatenes: 600,
    maxOvertime: 240,
    minVehicule: false,
    shortestDistance: false,
    balence: undefined,
    balenceCoef: 1,
    deliveryDate,
  }
}

export const parseFilterTournee = (filter) => {
  const parseFilter = {}
  Object.keys(filter).forEach((el) => {
    parseFilter[el] = filter[el]
      .filter((fil) => fil)
      .map((fil) => {
        return {
          code: fil,
          name: fil + '',
        }
      })
  })
  return parseFilter
}

export const ajusterChevauchementsMarqueurs = (markers) => {
  const OFFSET = 0.0002
  const positions = new Set()
  markers.forEach((marker, index) => {
    const position = marker.position
    let newPosition = position
    if (marker.infoText.props?.type !== 'pickup') {
      while (positions.has(`${newPosition.lat}_${newPosition.lng}`)) {
        newPosition = {
          lat: newPosition.lat + OFFSET,
          lng: newPosition.lng + OFFSET,
        }
      }

      if (newPosition !== position) {
        marker.position = newPosition
      }

      positions.add(`${newPosition.lat}_${newPosition.lng}`)
    }
  })

  return markers
}

export const parseReceptionNumber = (receptionNumber) => {
  const lastIndexOf = receptionNumber.lastIndexOf('-')

  return lastIndexOf != -1
    ? receptionNumber.substring(0, lastIndexOf)
    : receptionNumber
}

export const reorderFromUnservedInversionTournee = (
  positionsChauffeur,
  prevTours
) => {
  const simTours = [...prevTours]
  const changedTours = []
  let tourneeA = simTours[positionsChauffeur[0]].markers
  const coursesA = tourneeA.slice(1, tourneeA.length - 1)

  let tourneeB = simTours[positionsChauffeur[1]].markers
  const coursesB = tourneeB.slice(1, tourneeB.length - 1)

  simTours[positionsChauffeur[0]].markers = [
    tourneeA[0],
    ...coursesB,
    tourneeA[tourneeA.length - 1],
  ].map((item, i) => {
    item.order = i
    return item
  })

  simTours[positionsChauffeur[1]].markers = [
    tourneeB[0],
    ...coursesA,
    tourneeB[tourneeB.length - 1],
  ].map((item, i) => {
    item.order = i
    return item
  })

  changedTours.push(simTours[positionsChauffeur[0]], simTours[positionsChauffeur[1]])

  return {
    simTours: changedTours,
  }
}

export const reorderFromUnservedInversionOrderTournee = (
  positionsChauffeur,
  prevTours
) => {
  const simTours = [...prevTours]
  const changedTours = []

  positionsChauffeur.forEach((pos) => {
    const tournee = simTours[pos].markers
    const courses = tournee.slice(1, tournee.length - 1)

    const coursesPickup = courses.slice(0, courses.length / 2)
    const coursesDropOff = courses.slice(courses.length / 2, courses.length)
    const coursesReverse = [...coursesPickup, ...coursesDropOff.reverse()]

    simTours[pos].markers = [
      tournee[0],
      ...coursesReverse,
      tournee[tournee.length - 1],
    ].map((item, i) => {
      item.order = i
      return item
    })
    changedTours.push(simTours[pos])
  })

  return {
    simTours: changedTours,
  }
}

//parse Unserved From File
export const parseUnservedFromFile = (courses) => {
  return courses.map(
    ({
      receptionNumber,
      type,
      pickUpStart,
      pickUpEnd,
      dropOffStart,
      dropOffEnd,
      weight,
      volume,
      count,
      prestaTime,
      adresseArrivee,
      price,
      adresseDepart,
      keys,
      orderGiver,
      client,
      reason,
      secondDropOffStart,
      secondDropOffEnd,
    }) => {
      return {
        id: String(receptionNumber),
        tourId: undefined,
        driverId: undefined,
        endLocation: {
          name: adresseArrivee.address,
          lat: adresseArrivee.latitude,
          lng: adresseArrivee.longitude,
          identifier: adresseArrivee.id,
        },
        startLocation: {
          name: adresseDepart.address,
          lat: adresseDepart.latitude,
          lng: adresseDepart.longitude,
          identifier: adresseDepart.id,
        },
        weight: weight ? weight : 0,
        volume: volume ? volume : 0,
        montantPrestaHT: price ? price : 0,
        isManu: false,
        pickUpEnd: pickUpEnd,
        pickUpStart: pickUpStart,
        dropOffStart: dropOffStart,
        dropOffEnd: dropOffEnd,
        secondDropOffStart: secondDropOffStart,
        secondDropOffEnd: secondDropOffEnd,
        courseType: type,
        keys: keys,
        nbColis: count,
        prestaTime: prestaTime,
        receptionNumber: receptionNumber,
        codePostale: adresseArrivee?.postalCode,
        city: adresseArrivee?.city,
        locationType: adresseArrivee?.locationType,
        orderGiver: orderGiver,
        client: client,
        reason: reason,
      }
    }
  )
}

//parse Unserved From Legacy
export const parseUnservedFromLegacy = (courses, selectedCourses) => {
  return courses
    .filter((obj) => selectedCourses.includes(obj.code))
    .map(
      ({
        code,
        reason,
        alert,
        type,
        pickUpStart,
        pickUpEnd,
        dropOffStart,
        dropOffEnd,
        volume,
        weight,
        montantPrestataireHT,
        manutention,
        lettreDeVoiture,
        adresseDepart,
        adresseArrivee,
        courseMetadata,
        commande: { canalVente },
      }) => {
        return {
          id: code,
          tourId: undefined,
          driverId: undefined,
          endLocation: {
            name: adresseArrivee.address,
            lat: adresseArrivee.latitude,
            lng: adresseArrivee.longitude,
            identifier: adresseArrivee.id,
          },
          startLocation: {
            name: adresseDepart.address,
            lat: adresseDepart.latitude,
            lng: adresseDepart.longitude,
            identifier: adresseDepart.id,
          },
          weight: weight ? weight : 0,
          volume: volume ? volume : 0,
          montantPrestaHT: montantPrestataireHT ? montantPrestataireHT : 0,
          isManu: manutention,
          ldv: lettreDeVoiture?.code ? lettreDeVoiture?.code : undefined,
          pickUpEnd: pickUpEnd,
          pickUpStart: pickUpStart,
          dropOffStart: dropOffStart,
          dropOffEnd: dropOffEnd,
          courseType: courseMetadata?.courseType,
          salesChanel: {
            id: canalVente?.id,
            name: canalVente?.name,
            code: canalVente?.code,
          },
          receptionNumber: code,
          reason: reason,
          alert: alert ? alert : '-',
          type: type ? type : '-',
        }
      }
    )
}

//GET Courses Checked (Optimiser)
export const getCheckedUnserved = (courses, selectedCourses) => {
  return courses.filter((obj) => selectedCourses.includes(obj.id))
}

// get course from table tournee (Optimiser)
const parseCourse = (marker) => {
  if (marker.type == 'dropoff') {
    return {
      endLocation: {
        name: marker.location_name,
        lat: marker.lat,
        lng: marker.lng,
        identifier: marker.identifier,
      },
      dropOffStart: marker?.windowStart,
      dropOffEnd: marker?.windowEnd,
      secondDropOffStart: marker?.secondWindowStart,
      secondDropOffEnd: marker?.secondWindowEnd,
      courseType: marker?.courseType,
      salesChanel: {
        id: marker?.salesChanel?.id,
        name: marker?.salesChanel?.name,
        code: marker?.salesChanel?.code,
      },
      keys: marker?.keys,
      nbColis: marker?.nbColis,
      prestaTime: marker?.prestaTime,
      receptionNumber: marker?.location_id,
      codePostale: marker?.codePostale,
      locationType: marker?.locationType,
      orderGiver: marker?.orderGiver,
      client: marker?.client,
      reason: marker?.reason,
      city: marker?.city,
    }
  } else {
    return {
      id: marker.location_id,
      startLocation: {
        name: marker.location_name,
        lat: marker.lat,
        lng: marker.lng,
        identifier: marker.identifier,
      },
      weight: marker.weight ? marker.weight : 0,
      volume: marker.volume ? marker.volume : 0,
      receptionDate: marker.createdAt,
      montantPrestaHT: marker.montantPrestaHT ? marker.montantPrestaHT : 0,
      isManu: marker?.isManu,
      ldv: marker?.ldv ? marker?.ldv : undefined,
      pickUpEnd: marker?.windowEnd,
      pickUpStart: marker?.windowStart,
    }
  }
}

export const getParsedCourses = (planning, driverList) => {
  return Object.values(
    planning
      .filter((tour) => driverList.includes(tour.driver.id))
      .flatMap((item) => item.markers)
      .filter((visit) => visit.type != 'depotStart' && visit.type != 'depotEnd')
      .reduce((acc, marker) => {
        if (!acc[marker.location_id]) {
          acc[marker.location_id] = { id: marker.location_id }
        }
        Object.assign(acc[marker.location_id], parseCourse(marker))
        return acc
      }, {})
  )
}

// get Location from Unserved (Optimiser)
export const getLocationFromUnservedCourses = (unservedTours, selectedCourses) => {
  const unservedToursArray = unservedTours
    .filter((obj) => selectedCourses.includes(obj.id))
    .map((tour) => {
      if (ClientPickup.includes(tour.courseType)) {
        return tour.endLocation
      } else {
        return tour.startLocation
      }
    })
  return unservedToursArray
}

// remplace object (update course) FROM legacy
export const replaceInArrayUpdateFromLegacy = (data, object) => {
  return {
    ...data[object.codeCourse],
    endLocation: {
      ...data[object.codeCourse].endLocation,
      name: object.adresseArrivee.address,
      lat: object.adresseArrivee.latitude,
      lng: object.adresseArrivee.longitude,
    },
    codePostale: parseInt(object.adresseArrivee.postalCode),
    weight: parseInt(object.weight),
    volume: parseFloat(object.volume),
  }
}

// remplace object (update course) FROM POLARIS
export const replaceInArrayUpdateFromPolaris = (data, object) => {
  return {
    ...data[object.receptionNumber],
    endLocation: {
      ...data[object.receptionNumber].endLocation,
      name: object.adresseArrivee.address,
      lat: object.adresseArrivee.latitude,
      lng: object.adresseArrivee.longitude,
    },
    weight: parseInt(object.weight),
    volume: parseFloat(object.volume),
    nbColis: parseFloat(object.count),
    prestaTime: parseFloat(object.prestaTime),
    codePostale: parseInt(object.adresseArrivee.postalCode),
    montantPrestaHT: parseFloat(object.price),
    locationType: object.adresseArrivee.locationType,
    dropOffEnd: object.dropOffEnd,
    secondDropOffEnd: object.secondDropOffEnd,
    dropOffStart: object.dropOffStart,
    secondDropOffStart: object.secondDropOffStart,
    city: object.adresseArrivee.city,
    keys: object.keys,
  }
}

// Insert course from tournee to unserved
export const parseCourseFromTourneeToUnserved = (courses) => {
  return Object.values(courses).map((course) => {
    return {
      id: course.pickUpVisit.location_id,
      tourId: undefined,
      driverId: undefined,
      endLocation: {
        name: course.dropOffVisit.location_name,
        lat: course.dropOffVisit.lat,
        lng: course.dropOffVisit.lng,
        identifier: course.dropOffVisit.identifier,
      },
      startLocation: {
        name: course.pickUpVisit.location_name,
        lat: course.pickUpVisit.lat,
        lng: course.pickUpVisit.lng,
        identifier: course.pickUpVisit.identifier,
      },
      weight: course.pickUpVisit.weight ? course.pickUpVisit.weight : 0,
      volume: course.pickUpVisit.volume ? course.pickUpVisit.volume : 0,
      receptionDate: course.pickUpVisit.createdAt,
      montantPrestaHT: course.pickUpVisit.montantPrestaHT
        ? course.pickUpVisit.montantPrestaHT
        : 0,
      isManu: course.pickUpVisit?.isManu,
      ldv: course.pickUpVisit?.ldv ? course.pickUpVisit?.ldv : undefined,
      pickUpEnd: course.pickUpVisit?.windowEnd,
      pickUpStart: course.pickUpVisit?.windowStart,
      dropOffStart: course.dropOffVisit?.windowStart,
      dropOffEnd: course.dropOffVisit?.windowEnd,
      secondDropOffStart: course.dropOffVisit?.secondWindowStart,
      secondDropOffEnd: course.dropOffVisit?.secondWindowEnd,
      courseType: course.dropOffVisit?.courseType,
      earliestDeliveryDate: undefined,
      latestDeliveryDate: undefined,
      salesChanel: {
        id: course.dropOffVisit?.salesChanel?.id,
        name: course.dropOffVisit?.salesChanel?.name,
        code: course.dropOffVisit?.salesChanel?.code,
      },
      keys: course.dropOffVisit?.keys,
      nbColis: course.dropOffVisit?.nbColis ? course.dropOffVisit?.nbColis : 0,
      prestaTime: course.dropOffVisit?.prestaTime
        ? course.dropOffVisit?.prestaTime
        : 0,
      receptionNumber: course.dropOffVisit?.location_id,
      codePostale: course.dropOffVisit?.codePostale,
      locationType: course.dropOffVisit?.locationType,
      orderGiver: course.dropOffVisit?.orderGiver,
      client: course.dropOffVisit?.client,
      reason: undefined,
      city: course.dropOffVisit?.city,
    }
  })
}

// Get dropOffVisit from unserved
const getDropOffVisitFromUnserved = (course) => {
  const salesChanel = {
    id: course?.salesChanel?.id,
    name: course?.salesChanel?.name,
    code: course?.salesChanel?.code,
  }
  return {
    reason: undefined,
    windowStart: course.dropOffStart,
    windowEnd: course.dropOffEnd,
    volume: course?.volume ? course.volume : 0,
    weight: course?.weight ? course.weight : 0,
    montantPrestaHT: course?.montantPrestaHT ? course.montantPrestaHT : 0,
    ldv: course?.ldv ? course.ldv : undefined,
    lat: course.endLocation.lat,
    lng: course.endLocation.lng,
    identifier: course.endLocation.identifier,
    location_id: course.id,
    location_name: course.endLocation.name,
    isManu: course.isManu,
    courseType: course?.courseType,
    salesChanel:
      Object.keys(removeNull(salesChanel)).length != 0 ? salesChanel : undefined,
    secondWindowStart: course?.secondDropOffStart
      ? course.secondDropOffStart
      : undefined,
    secondWindowEnd: course?.secondDropOffEnd ? course.secondDropOffEnd : undefined,
    keys: course?.keys ? course.keys : undefined,
    nbColis: course?.nbColis ? course.nbColis : 0,
    prestaTime: course?.prestaTime ? course.prestaTime : 0,
    codePostale: course?.codePostale ? course.codePostale : undefined,
    locationType: course?.locationType ? course.locationType : undefined,
    orderGiver: course?.orderGiver ? course.orderGiver : undefined,
    client: course?.client ? course.client : undefined,
    type: 'dropoff',
    city: course?.city ? course.city : undefined,
  }
}

// Get dropOffVisit from unserved
const getPickUpVisitFromUnserved = (course) => {
  const salesChanel = {
    id: course?.salesChanel?.id,
    name: course?.salesChanel?.name,
    code: course?.salesChanel?.code,
  }
  return {
    reason: undefined,
    windowStart: course.pickUpStart,
    windowEnd: course.pickUpEnd,
    volume: course?.volume ? course.volume : 0,
    weight: course?.weight ? course.weight : 0,
    montantPrestaHT: course?.montantPrestaHT ? course.montantPrestaHT : 0,
    ldv: course?.ldv ? course.ldv : undefined,
    lat: course.startLocation.lat,
    lng: course.startLocation.lng,
    identifier: course.startLocation.identifier,
    location_id: course.id,
    location_name: course.startLocation.name,
    isManu: course.isManu,
    courseType: course?.courseType,
    salesChanel:
      Object.keys(removeNull(salesChanel)).length != 0 ? salesChanel : undefined,
    keys: course?.keys ? course.keys : undefined,
    nbColis: course?.nbColis ? course.nbColis : 0,
    prestaTime: course?.prestaTime ? course.prestaTime : 0,
    orderGiver: course?.orderGiver ? course.orderGiver : undefined,
    client: course?.client ? course.client : undefined,
    type: 'pickup',
  }
}

// Get dropOffVisit from unserved
export const deleteFromUnserved = (courses, ids) => {
  return courses.filter((item) => !ids.includes(item.id))
}

// remove null and undefined
const removeNull = (obj) => {
  for (let key in obj) {
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === 0 ||
      obj[key] === '' ||
      (Array.isArray(obj[key]) && obj[key].length == 0)
    ) {
      delete obj[key]
    }
  }
  return obj
}

//Eliminer la duplication
export const deleteDuplicatedCourses = (tab1, tab2) => {
  const tab1Object = tab1.reduce((a, v) => ({ ...a, [v.id]: v }), {})
  const tab2Object = tab2.reduce((a, v) => ({ ...a, [v.id]: v }), {})
  return [
    ...Object.values({
      ...tab1Object,
      ...tab2Object,
    }),
  ]
}

export const updateTourRecChecked = (tourRecChecked, receptionNumber, type) => {
  const entry = tourRecChecked[receptionNumber] || {}

  // Si `type` existe et est `true`, le supprimer
  if (entry[type]) {
    delete entry[type]
  } else {
    // Sinon, l'ajouter avec la valeur `true`
    entry[type] = true
  }
  // Vérifier si tous les champs de l'entrée sont `false` ou si elle est vide
  if (Object.keys(entry).length === 0) {
    delete tourRecChecked[receptionNumber]
  } else {
    // Sinon, mettre à jour `receptionNumber` dans l'objet
    tourRecChecked[receptionNumber] = entry
  }
  return tourRecChecked
}
