export const COURSE_SOURCE_DUPLICATED = [
  'MDM',
  'SCHMIDT',
  'EMMA',
  'ECOMATELAS',
  'RDVDECO',
  'KLAREO',
]

export const COURSE_SOURCE_DUPLICATED_LIVRAISON_IMPOSSIBLE = ['LECLERC']

export const COURSE_SOURCE_ETAT_POINTAGE = ['MDM']
export const COURSE_SOURCE_EDIT_PACKAGE = ['MDM']
export const COURSE_SOURCE_CALENDAR = ['MDM', 'SCHMIDT', 'CONFORAMA', 'RDVDECO']
export const COURSE_SOURCE_CALENDAR_DISABLED = ['CONFORAMA']
export const COURSE_SOURCE_BLOQUAGE_PROGRAMMATION = ['MDM']
export const COURSE_SOURCE_BLOQUAGE_CHECK_SEND_MAIL_PROGRAMMATION = [
  'MDM',
  'CONFORAMA',
  'RDVDECO',
]

export const COURSE_SOURCE_DATE_ENLEVEMENT = ['LECLERC']
