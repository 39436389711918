import moment from 'moment'
import { tourType } from './values'
const getToursInfos = (
  simulatedTours,
  isOnePickup,
  isOptimisationCoursesFiles,
  isDownload
) => {
  return {
    tours: simulatedTours.map((tour) => {
      return {
        path: tour.path[0],
        distance: tour.distance,
        ldt: tour.ldt ? tour.ldt : undefined,
        driver: {
          id: String(tour.driver.id),
          firstName: tour.driver.firstName,
          name: tour.driver.name,
          vehiculeType: tour.driver?.vehiculeType,
          competences: tour.driver.competences,
        },
        deliveryDate: tour.deliveryDate,
        tourType:
          isOnePickup === true ? tourType.ONE_PICK_UP : tourType.MULTI_PICK_UP,
        visits: tour.markers.map((visit, index, arr) => {
          return {
            distance: visit.distance,
            rank: visit.order,
            estimatedArrivalTime: moment.tz(
              visit.estimatedArrivalTime,
              'Europe/Paris'
            ),
            estimatedDepartureTime: moment.tz(
              visit.estimatedDepartureTime,
              'Europe/Paris'
            ),
            type: visit.type,
            ldv: visit.ldv,
            isManu: visit.isManu,
            windowEnd: visit.windowEnd,
            windowStart: visit.windowStart,
            secondWindowEnd: visit.secondWindowEnd,
            secondWindowStart: visit.secondWindowStart,
            cachedAddress: {
              code: visit.identifier ? visit.identifier : arr[index + 1].identifier,
              lat: visit.lat ? visit.lat : arr[index + 1].lat,
              lng: visit.lng ? visit.lng : arr[index + 1].lng,
              locationName: visit.location_name,
            },
            idleTime: visit.idleTime,
            course: {
              codeCourse: visit.location_id,
              weight: visit.weight,
              volume: visit.volume,
              montantPrestaHT: visit.montantPrestaHT,
              courseType: visit.courseType,
              salesChanel: visit.salesChanel,
              deliveryDate: visit.windowStart,
            },
            client: visit.client,
            prestaTime: visit.prestaTime,
            keys: visit.keys,
            codePostale: visit.codePostale,
            orderGiver: visit.orderGiver,
            lateBy: visit.lateBy,
            slotOverRun: visit.lateBy,
            rejectedKeys: visit.rejectedKeys,
            city: visit.city,
            locationType: visit.locationType,
            nbColis: visit.nbColis,
            // eslint-disable-next-line camelcase
            location_id: visit.location_id,
          }
        }),
        tourPrice: tour.montantPrestataireHT,
        totalWeight: tour.totalWeight,
        tourPickupWeight: tour.tourPickupWeight,
        tourDropoffWeight: tour.tourDropoffWeight,
        tourVolume: tour.totalVolume,
        tourDuration: String(tour.totalTime),
        tourTravel: String(tour.trajetTime),
        stopsNumber: tour.stopsNumber,
        exceededAmplitude: tour.exceededAmplitude,
      }
    }),
    userId: String(JSON.parse(localStorage.getItem('currentUser'))?.id),
    fileExtension: isOptimisationCoursesFiles ? 'XLSX' : undefined,
    download: isDownload ? true : false,
  }
}

export default getToursInfos
