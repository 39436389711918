import React from 'react'
import PropTypes from 'prop-types'

import { StyledCell } from './style'
import Box from '@mui/material/Box'
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone'
const ColumnTextTableEditable = ({
  content,
  id,
  onChange,
  nameAttribut,
  disabledColumn,
  valuesmodif,
}) => {
  const tab = () => (valuesmodif != '-' ? valuesmodif : content)

  const onDeleteElement = (position) => {
    const newContent = [...tab()]
    newContent.splice(position, 1)
    onChange({ [`${nameAttribut}`]: newContent })
  }

  return (
    <StyledCell id={id} sx={{ color: disabledColumn && '#8E8E8E' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {tab().map((el, index) => (
          <Box
            key={index}
            sx={{
              margin: '3px',
              padding: '3px',
              fontSize: '0.8125rem',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'rgba(0, 0, 0, 0.87)',
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              borderRadius: '16px',
              whiteSpace: 'nowrap',
              verticalAlign: 'middle',
            }}
          >
            <Box
              component={'span'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingLeft: '12px',
                paddingRight: '12px',
                whiteSpace: 'nowrap',
              }}
            >
              {el}
            </Box>
            <HighlightOffTwoToneIcon
              onClick={() => onDeleteElement(index)}
              sx={{
                fontSize: '22px',
                cursor: 'pointer',
                margin: '0 5px 0 -6px',
                color: 'rgba(0, 0, 0, 0.26)',
                ':hover': {
                  color: 'rgb(246 16 87);',
                },
              }}
            />
          </Box>
        ))}
      </Box>
    </StyledCell>
  )
}
ColumnTextTableEditable.propTypes = {
  content: PropTypes.array,
  key: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  nameAttribut: PropTypes.string,
  disabledColumn: PropTypes.bool,
  valuesmodif: PropTypes.string,
}

export default ColumnTextTableEditable
