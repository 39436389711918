export const checkActionsUservedOrders = [
  {
    id: 'check',
    label: 'Selectionner',
    name: 'Selectionner',
  },
]

export const buttonActionUservedOrders = [
  {
    id: 'action',
    label: '',
    minWidth: 70,
    name: 'Action',
  },
]

export const settingsTable = [
  {
    id: 'settings',
    label: 'Settings',
    minWidth: 100,
    name: 'Settings',
  },
]

export const columnsListUservedOrders = [
  {
    id: 'index',
    label: 'Order',
    minWidth: 50,
    filter: false,
  },
  {
    id: 'dropoff',
    label: 'Créneau de livraison',
    minWidth: 135,
  },
  {
    id: 'courseType',
    label: 'Type de course',
    minWidth: 100,
  },
  {
    id: 'endLocation',
    label: "Adresse d'arrivée",
    minWidth: 310,
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 100,
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 100,
  },
  {
    id: 'price',
    label: 'Prix',
    minWidth: 100,
  },
  {
    id: 'ldv',
    label: 'LDV',
    minWidth: 100,
  },
  {
    id: 'salesChannel',
    label: 'Canal Vente',
    minWidth: 100,
  },
]

export const columnsOptimisationCoursesFileUservedOrders = [
  {
    id: 'index',
    label: 'Order',
    minWidth: 30,
    filter: false,
  },
  {
    id: 'receptionNumber',
    label: 'Récep',
    minWidth: 100,
    filter: true,
    codeFilter: 'receptionNumber',
  },
  {
    id: 'courseType',
    label: 'Type',
    minWidth: 70,
    filter: true,
    codeFilter: 'courseType',
  },
  {
    id: 'creneauArivee1',
    label: 'Créneau 1',
    minWidth: 150,
    filter: false,
  },
  {
    id: 'creneauArivee2',
    label: 'Créneau 2',
    minWidth: 150,
    filter: false,
  },
  {
    id: 'client',
    label: 'Client',
    minWidth: 135,
    filter: true,
    codeFilter: 'client',
  },
  {
    id: 'orderGiver',
    label: 'DO',
    minWidth: 135,
    filter: true,
    codeFilter: 'orderGiver',
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 50,
    filter: true,
    codeFilter: 'weight',
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 50,
    filter: false,
  },
  {
    id: 'nbColis',
    label: 'NB colis',
    minWidth: 50,
    filter: false,
  },
  {
    id: 'prestaTime',
    label: 'Temps de prestation',
    minWidth: 100,
    filter: false,
  },
  {
    id: 'endLocation',
    label: "Adresse d'arrivée",
    minWidth: 310,
    filter: true,
    codeFilter: 'endLocation',
  },
  {
    id: 'city',
    label: 'Ville',
    minWidth: 50,
    filter: true,
    codeFilter: 'city',
  },
  {
    id: 'codePostale',
    label: 'CP',
    minWidth: 70,
    filter: true,
    codeFilter: 'codePostale',
  },
  {
    id: 'price',
    label: 'Prix',
    minWidth: 50,
    filter: false,
  },
  {
    id: 'keys',
    label: 'Clés',
    minWidth: 100,
    filter: true,
    codeFilter: 'keys',
  },
]

export const columnsOptimisationCoursesFileUservedOrdersModif = [
  {
    id: 'receptionNumber',
    label: 'Récep',
    minWidth: 70,
  },
  {
    id: 'client',
    label: 'Client',
    minWidth: 135,
  },
  {
    id: 'orderGiver',
    label: "Donneur d'ordre",
    minWidth: 70,
  },
  {
    id: 'creneauArivee1',
    label: 'Créneau 1',
    minWidth: 153,
  },
  {
    id: 'creneauArivee2',
    label: 'Créneau 2',
    minWidth: 153,
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 64,
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 65,
  },
  {
    id: 'nbColis',
    label: 'NB colis',
    filter: false,
    minWidth: 55,
  },
  {
    id: 'prestaTime',
    label: 'Temps de prestation',
    minWidth: 55,
  },
  {
    id: 'endLocation',
    label: "Adresse d'arrivée",
    minWidth: 360,
  },
  {
    id: 'keys',
    label: 'Clés',
    minWidth: 250,
  },
]

export const columnsListUservedOrdersModif = [
  {
    id: 'ldv',
    label: 'Lettre de voiture',
    minWidth: 50,
  },

  {
    id: 'endLocation',
    label: "Adresse d'arrivée",
    minWidth: 360,
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 82,
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 82,
  },
]

//dialog courses with reason
export const columnsOptimisationCoursesFileUservedReason = [
  {
    id: 'receptionNumber',
    label: 'Récep',
    minWidth: 70,
  },
  {
    id: 'client',
    label: 'Client',
    minWidth: 135,
  },
  {
    id: 'orderGiver',
    label: 'DO',
    minWidth: 70,
  },
  {
    id: 'creneauArivee1',
    label: 'Créneau 1',
    minWidth: 153,
  },
  {
    id: 'creneauArivee2',
    label: 'Créneau 2',
    minWidth: 153,
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 64,
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 65,
  },
  {
    id: 'nbColis',
    label: 'NB colis',
    filter: false,
    minWidth: 55,
  },
  {
    id: 'prestaTime',
    label: 'Temps de prestation',
    minWidth: 55,
  },
  {
    id: 'endLocation',
    label: "Adresse d'arrivée",
    minWidth: 360,
  },
  {
    id: 'keys',
    label: 'Clés',
    minWidth: 100,
  },
  {
    id: 'reason',
    label: 'Raison',
    minWidth: 100,
  },
]

export const columnsListUservedOrdersReason = [
  {
    id: 'ldv',
    label: 'Lettre de voiture',
    minWidth: 50,
  },

  {
    id: 'endLocation',
    label: "Adresse d'arrivée",
    minWidth: 360,
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 82,
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 82,
  },
  {
    id: 'reason',
    label: 'Raison',
    minWidth: 100,
    filter: false,
  },
]
