import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Box from '@mui/material/Box'

import AvertissementTitlePopIcon from '../../assets/icons/AvertissementTitlePopIcon'
import AvertissementBodyPopIcon from '../../assets/icons/AvertissementBodyPopIcon'
import { Link } from '@mui/material'

const DialogCostumized = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    maxHeight: 'calc(100% - 157px)',
  },
  '& .MuiDialogContent-root': {
    '@media (min-width: 600px)': {
      width: '500px',
    },
  },
  '& .MuiDialogActions-root': {
    padding: '12px',
  },
}))

export default function ModalError({
  open,
  handleClose,
  message,
  messageLink,
  isModalActionActualiser,
}) {
  return (
    <DialogCostumized open={open} id={'dialog-Avertissement'}>
      <DialogTitle
        sx={{
          m: 0,
          color: 'white',
          padding: '20px',
          background: '#F61057',
          display: 'flex',
        }}
        id={'dialog-title'}
      >
        <AvertissementTitlePopIcon
          style={{
            width: '20px',
            height: '20px',
            marginRight: '6px',
            color: 'white',
          }}
        />
        {'Avertissement'}
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: 'grid', placeItems: 'center' }}>
          <AvertissementBodyPopIcon
            style={{
              width: '48px',
              height: '48px',
              marginBottom: '20px',
              color: '#373D57',
            }}
          />
          <Box>
            <Box sx={{ fontSize: '13px' }}>{message}</Box>
            {messageLink && (
              <Box sx={{ paddingTop: '10px' }}>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={messageLink}
                  underline="none"
                >
                  {messageLink}
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          id={'button-annuler'}
          variant={'contained'}
          onClick={handleClose}
          sx={{
            width: '120px',
            marginLeft: '15px',
            marginRight: '12px',
            textTransform: 'none ',
            fontSize: '13px ',
            fontWeight: '400 ',
            backgroundColor: '#f61057',
            ':hover': {
              backgroundColor: '#d32f2f',
            },
          }}
        >
          {isModalActionActualiser ? 'Actualiser' : 'Fermer'}
        </Button>
      </DialogActions>
    </DialogCostumized>
  )
}

ModalError.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  message: PropTypes.string,
  messageLink: PropTypes.string,
  isModalActionActualiser: PropTypes.bool,
}
