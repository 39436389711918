import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  GoogleMap,
  DirectionsRenderer,
  useLoadScript,
  DrawingManager,
  Polygon,
  Rectangle,
  Circle,
  Polyline,
} from '@react-google-maps/api'
import PropTypes from 'prop-types'
import './style.scss'
import { GOOGLE_MAP_CONSTANTS } from '../../constants/googleMapConstants'
import { LANGUAGE } from '../../constants/appContants'
import MarkerWithEticket from './Markers/MarkerWithEticket'
import MarkerWithTitle from './Markers/MarkerWithTitle'
import CustomInfoWindow from './Markers/InfoWindow'
import GestureIcon from '@mui/icons-material/Gesture'
import ReplayIcon from '@mui/icons-material/Replay'
import IconButton from '@mui/material/IconButton'
import { decode } from '@mapbox/polyline'
import MarkerWithBorder from './Markers/MarkerWithBorder'
import { SELECTED_COURSE_ENUM } from '../../utils/values'
import DeselectIcon from '@mui/icons-material/Deselect'
import Tooltip from '@mui/material/Tooltip'
import { ajusterChevauchementsMarqueurs } from '../../utils/tours'

const librarries = ['drawing', 'geometry']

const GoogleMapComponent = ({
  setScriptLoadedSuccess,
  setScriptLoadedError,
  mapOptions,
  directions,
  markers,
  mapContainerStyle,
  mapId,
  isDrawable,
  polyLines,
  setUnselectTrigger,
  unselectTrigger,
  focusedTours,
  removeSelectedCourses,
  addSelectedCourses,
  selectedCourses,
  removeAllSelected,
}) => {
  const [showIndex, setShow] = useState(-1)
  const [enableDrawing, setEnableDrawing] = useState(false)
  const [overlays, setOverlays] = useState([])
  //const [previousSelectedMarkers, setPreviousSelectedMarkers] = useState([])
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    googleMapsClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    language: { LANGUAGE },
    libraries: librarries,
  })
  const isProgrammerPage = window.location.pathname.includes('programmer')
    ? true
    : false
  const colorArray = [
    '#2979ff',
    '#ff1744',
    '#76ff03',
    '#ff00ff',
    '#ff6d00',
    '#ffff00',
    '#00e5ff',
    '#ff5252',
    '#00c853',
    '#aa00ff',
    '#ff9100',
    '#ffd600',
    '#304ffe',
    '#d50000',
    '#b2ff59',
    '#e040fb',
    '#ffab40',
    '#ffff00',
    '#00b8d4',
    '#d500f9',
    '#6200ea',
  ]
  const [path, setPath] = useState([])
  //const [precision, setprecision] = useState(5)
  // Adds options as in showing points of interest removing popUp text on default markers etc ..
  const MapStyles = [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ]

  const onMapLoaded = () => {
    setScriptLoadedSuccess()
    if (polyLines?.length > 0) {
      const decoded = polyLines.map((poly) => {
        const int = decode(poly.path[0], 6).map((e) => {
          return { lat: e[0], lng: e[1] }
        })
        return { ...poly, path: int }
      })

      setPath(decoded)
    }
  }
  useEffect(() => {
    if (polyLines?.length >= 0) {
      const decoded = polyLines.map((poly) => {
        if (poly !== undefined && poly.path[0] !== null) {
          const int = decode(poly.path[0], 6).map((e) => {
            return { lat: e[0], lng: e[1] }
          })
          return { ...poly, path: int }
        }
      })
      setPath(decoded)
    }
  }, [polyLines])

  useEffect(() => {
    if (isDrawable && unselectTrigger) {
      setOverlays([])
      setUnselectTrigger(false)
    }
  }, [unselectTrigger])

  useEffect(() => {
    if (selectedCourses?.length == 0) {
      setOverlays([])
    }
  }, [selectedCourses])

  useEffect(() => {
    if (isDrawable) {
      //Get Points inside the geometric
      let polyLimits = []
      let bounds = []
      // let circleBounds = []
      overlays.map((e) => {
        if (e.type === 'polygon') polyLimits.push(getPolygonLimits(e.overlay))
        if (e.type === 'rectangle' || e.type === 'circle')
          bounds.push(e.overlay.getBounds())
      })
      //Get if marker is selcted inside one of the geometric
      const selectedMarkers = markers
        .filter(({ isFocused }) => isFocused)
        .map((e) => {
          const markerLatLng = [e.position.lat, e.position.lng]
          if (polyLimits?.length > 0) {
            for (const polyLimit of polyLimits) {
              if (isPointInsidePolygon(markerLatLng, polyLimit)) {
                return e.codeCourse
              }
            }
          }
          if (bounds?.length > 0) {
            for (let i = 0; i < bounds.length; i++) {
              if (
                bounds[i]?.contains(
                  new window.google.maps.LatLng(markerLatLng[0], markerLatLng[1])
                )
              ) {
                return e.codeCourse
              }
            }
          }
        })

      //Filter the undefined options
      const filteredSelectedMarkers = selectedMarkers.filter((e) => e !== undefined)

      //TODO: USE SET FOR A-B
      const toAdd = filteredSelectedMarkers?.filter(
        (e) => !selectedCourses?.includes(e)
      )

      const toRemove = selectedCourses?.filter(
        (e) => !filteredSelectedMarkers?.includes(e)
      )
      if (toAdd?.length > 0) {
        addSelectedCourses({ data: toAdd, source: SELECTED_COURSE_ENUM.MAP })
      }
      if (toRemove?.length > 0) {
        removeSelectedCourses({ data: toRemove, source: SELECTED_COURSE_ENUM.MAP })
      }
    }
  }, [overlays])
  useEffect(() => {
    return () => {
      setScriptLoadedError()
    }
  }, [])
  const handleAddOnClick = (codeCourse) => {
    if (!selectedCourses.includes(codeCourse)) {
      addSelectedCourses({ data: [codeCourse], source: SELECTED_COURSE_ENUM.MAP })
    } else {
      removeSelectedCourses({ data: [codeCourse], source: SELECTED_COURSE_ENUM.MAP })
    }
  }
  const handleKeyPress = (event) => {
    // Check for specific key combination, e.g., Ctrl + S

    if (event.ctrlKey && event.key === 'z') {
      event.preventDefault()
      // Prevent the default browser action
      // Add your event logic here
      onUndoClick()
    }
    if (event.shiftKey) {
      event.preventDefault()
      setEnableDrawing(!enableDrawing)
      // Prevent the default browser action
      // Add your event logic here
    }
  }
  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  const onEnableDrawing = () => {
    setEnableDrawing(!enableDrawing)
  }
  const getPolygonLimits = (polygon) => {
    var polygonBounds = polygon?.getPath()
    var bounds = []
    for (var i = 0; i < polygonBounds?.length; i++) {
      var point = {
        lat: polygonBounds?.getAt(i).lat(),
        lng: polygonBounds?.getAt(i).lng(),
      }
      bounds.push(point)
    }
    return bounds.map((e) => [e.lat, e.lng])
  }

  // TODO : delete polygon func
  const isPointInsidePolygon = (point, vs) => {
    var x = point[0],
      y = point[1]

    var inside = false
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0],
        yi = vs[i][1]
      var xj = vs[j][0],
        yj = vs[j][1]

      var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) inside = !inside
    }

    return inside
  }

  // const handleRemoveCourses = () => {
  //   const a = previousSelectedMarkers.pop()
  //   console.log(a, 'toRem')
  //   console.log('toRe', selectedCourses)
  //   // this should be changedI
  //   const toRemove = selectedCourses.filter((e) => !a.includes(e))
  //   console.log('toRemove', toRemove)
  //   removeSelectedCourses({
  //     data: toRemove,
  //     source: SELECTED_COURSE_ENUM.MAP,
  //   })
  // }

  const onUndoClick = () => {
    setOverlays([...overlays].slice(0, -1))
    // handleRemoveCourses()
  }

  const RenderDrawControls = () => {
    return (
      <div className="draw-controls">
        {selectedCourses.length > 0 && (
          <Tooltip title="Tout déselectionner" placement="bottom">
            <IconButton
              sx={{
                padding: '7px',
                backgroundColor: 'rgb(255 255 255) ',
                borderRadius: 'unset',
                ':hover': {
                  color: 'rgb(51 51 51)',
                  backgroundColor: 'rgb(255 255 255)',
                },
              }}
              aria-label="undo"
              component="span"
              onClick={removeAllSelected}
            >
              <DeselectIcon style={{ fontSize: '2rem' }} />
            </IconButton>
          </Tooltip>
        )}
        <IconButton
          sx={{
            padding: '7px',
            backgroundColor: 'rgb(255 255 255)',
            borderRadius: 'unset',
            ':hover': {
              color: 'rgb(51 51 51)',
              backgroundColor: 'rgb(255 255 255)',
            },
          }}
          aria-label="undo"
          component="span"
          onClick={onUndoClick}
        >
          <ReplayIcon style={{ fontSize: '2rem' }} />
        </IconButton>
        <IconButton
          sx={{
            padding: '7px',
            backgroundColor: 'rgb(255 255 255)',
            borderRadius: 'unset',
            ':hover': {
              color: 'rgb(51 51 51)',
              backgroundColor: 'rgb(255 255 255)',
            },
          }}
          aria-label="draw"
          component="span"
          onClick={onEnableDrawing}
        >
          <GestureIcon style={{ fontSize: '2rem' }} />
        </IconButton>
      </div>
    )
  }

  const renderOverlay = () => {
    const options = {
      fillColor: '#199ee0',
      fillOpacity: 0.2,
      strokeWeight: 2,
      strokeColor: '#113460',
      clickable: true,
      editable: true,
      geodesic: false,
      visible: true,
      zIndex: 1,
    }
    return overlays.map((overlay, i) => {
      switch (overlay.type) {
        case 'polygon':
          return (
            <Polygon path={overlay.overlay.getPath()} key={i} options={options} />
          )
        case 'rectangle':
          return (
            <Rectangle
              bounds={overlay.overlay.getBounds()}
              key={i}
              options={options}
            />
          )
        case 'circle':
          return (
            <Circle
              center={overlay.overlay.getCenter()}
              radius={overlay.overlay.getRadius()}
              key={i}
              options={options}
            />
          )
        default:
          return null
      }
    })
  }
  const renderMap = () => {
    return (
      <>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          id={mapId}
          zoom={mapOptions.zoom}
          center={mapOptions.center}
          onLoad={onMapLoaded}
          options={{
            ..._.get(mapOptions, 'center', GOOGLE_MAP_CONSTANTS.MAP_OPTIONS),
            ...GOOGLE_MAP_CONSTANTS.DEFAULT_UI_OPTIONS,
            styles: MapStyles,
          }}
        >
          {directions &&
            directions.length &&
            directions.map((d, index) => (
              <DirectionsRenderer
                key={`direction_${index}`}
                // required
                directions={d.directions}
                // optional
                onLoad={(directionsRenderer) => {
                  console.log(
                    'DirectionsRenderer onLoad directionsRenderer: ',
                    directionsRenderer
                  )
                }}
                // optional
                // onUnmount={(directionsRenderer) => {
                //   console.log(
                //     'DirectionsRenderer onUnmount directionsRenderer: ',
                //     directionsRenderer
                //   )
                // }}
                routeIndex={d.selectedDirection ? d.selectedDirection.index : 0}
              />
            ))}
          {window.google &&
            path.map((e, i) => {
              return (
                <Polyline
                  key={i}
                  path={e.path}
                  options={{
                    strokeColor:
                      e?.color != '#000000'
                        ? e.color
                        : colorArray[i % colorArray.length],
                    strokeOpacity:
                      focusedTours && focusedTours.length > 0
                        ? focusedTours.includes(e.index)
                          ? 1
                          : 0
                        : 0,
                    strokeWeight: 5,
                  }}
                />
              )
            })}

          {markers &&
            window.google &&
            markers.length &&
            ajusterChevauchementsMarqueurs(markers)?.map((m, i) => (
              <div key={`marker_info_${i}`}>
                {m.color ? (
                  (focusedTours.includes(m.index) ||
                    m.ticket === 'R' ||
                    m.ticket === 'L') && (
                    <MarkerWithBorder
                      marker={m}
                      showIndex={showIndex}
                      index={i}
                      setShow={setShow}
                      strokeColor={m.color}
                      onSelectMark={handleAddOnClick}
                    />
                  )
                ) : m.ticket || isDrawable ? (
                  <MarkerWithEticket
                    disableDraw={isProgrammerPage}
                    marker={m}
                    showIndex={showIndex}
                    index={i}
                    setShow={setShow}
                    isDrawable={isDrawable}
                  />
                ) : (
                  <MarkerWithTitle marker={m} index={i} />
                )}
                {showIndex === i && (
                  <CustomInfoWindow
                    content={m.ticket || isDrawable ? m.infoText : m.title}
                    index={i}
                    position={m.position}
                  />
                )}
              </div>
            ))}
          {isDrawable && <RenderDrawControls />}
          {isDrawable && window.google && enableDrawing && (
            <DrawingManager
              drawingMode={'rectangle'}
              onOverlayComplete={(overlay) => {
                setOverlays([...overlays, overlay])
                overlay.overlay.setMap(null)
              }}
              defaultOptions={{
                drawingControl: true,
                drawingControlOptions: {
                  position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                  drawingModes: [
                    window.google.maps.drawing.OverlayType.POLYGON,
                    window.google.maps.drawing.OverlayType.RECTANGLE,
                    window.google.maps.drawing.OverlayType.CIRCLE,
                  ],
                },
              }}
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
                  drawingModes: [
                    window.google.maps.drawing.OverlayType.POLYGON,
                    window.google.maps.drawing.OverlayType.RECTANGLE,
                    window.google.maps.drawing.OverlayType.CIRCLE,
                  ],
                },
              }}
            />
          )}
          {isDrawable && !unselectTrigger && overlays.length > 0 && renderOverlay()}
        </GoogleMap>
      </>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <div>Loading...</div>
}

GoogleMapComponent.propTypes = {
  setScriptLoadedSuccess: PropTypes.func.isRequired,
  setScriptLoadedError: PropTypes.func.isRequired,
  directions: PropTypes.array,
  markers: PropTypes.array,
  mapOptions: PropTypes.object.isRequired,
  isDrawable: PropTypes.bool,
  polyLines: PropTypes.any,
  setUnselectTrigger: PropTypes.func,
  unselectTrigger: PropTypes.bool,
  focusedTours: PropTypes.array,
  addSelectedCourses: PropTypes.func,
  removeSelectedCourses: PropTypes.func,
  selectedCourses: PropTypes.array,
  /** Used for adding style to the container */
  mapContainerStyle: PropTypes.any,
  /** the className of the mapContainer */
  mapId: PropTypes.any,
  removeAllSelected: PropTypes.func,
}
export default GoogleMapComponent
