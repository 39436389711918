import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../index.scss'
import Button from '@mui/material/Button'
import SimulateMap from '../../../../containers/main/Programmer/simulationMap'
import { getToursInfos } from '../../../../utils'
import ToursDetails from '../../../../containers/main/Simuler/ToursDetails'
import {
  existingPlanning,
  getFleetParse,
  getLocationFromUnservedCourses,
  getParsedCourses,
  locationCheck,
  reorderFromUnserved,
  reorderTours,
  validateLocationOnePickUp,
  reorderFromUnservedDeattribute,
  reorderFromUnservedInversionTournee,
  reorderFromUnservedInversionOrderTournee,
  getCheckedUnserved,
} from '../../../../utils/tours'
import UnservedOrders from '../../../../containers/main/Simuler/UnservedOrders'
import ModalError from '../../../../components/ModalError'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '../../../../components/ModalDialog'
import IconDistribuer from '../../../../assets/icons/polaris/iconeDistribuer'

import './index.scss'

import DriverModal from './components/driverModal'
import { Backdrop, Menu, MenuItem } from '@mui/material'
import FilterIcon from '../../../../assets/icons/iconFilterTableCell'
import {
  ACCURATE_ADDRESS,
  filterTab,
  filterTournee,
  MESSAGE_CONFIRMATION_POLARIS,
} from '../../../../utils/values'
import { Prompt } from 'react-router-dom/cjs/react-router-dom.min'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'
import { DetailTounerContainer, MapContainer, TopContainer } from './styled'
import _ from 'lodash'
import { Download } from '@mui/icons-material'

function MapProgrammer(props) {
  const {
    planning,
    unservedTours,
    isOptimisationCoursesFiles,
    isSimLoading,
    displayNone,
  } = props
  //resize windows VERTICAL
  return (
    <MapContainer displayNone={displayNone}>
      <SimulateMap
        mapContainerStyle={{
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
        mapId={''}
        tours={planning.map((tour) => {
          return {
            ...tour,
            color: tour.color,
            markers: tour.markers.map((mark) => {
              return {
                ...mark,
                color: tour.color,
              }
            }),
          }
        })}
        unservedTours={unservedTours}
        isOptimisationCoursesFiles={isOptimisationCoursesFiles}
        isSimLoading={isSimLoading}
      />
    </MapContainer>
  )
}
MapProgrammer.propTypes = {
  planning: PropTypes.any,
  unservedTours: PropTypes.any,
  isOptimisationCoursesFiles: PropTypes.bool,
  isSimLoading: PropTypes.bool,
  displayNone: PropTypes.bool,
}
export default function Plannification({
  unservedTours,
  createTours,
  handleToursReOrder,
  planning,
  fleet,
  simulateTours,
  handleCheck,
  resetCheckedAll,
  changeToursTypeState,
  isOnePickUp,
  viewPlannification,
  isSimLoading,
  isSimError,
  simulationErrorMessage,
  removeError,
  isErrorAffectPlanning,
  activeTourAction,
  isOptimisationCoursesFiles,
  exportTours,
  filtersValues,
  fetchCourses,
  selectedCourses,
  focusedTours,
  tourRecChecked,
}) {
  const [openDeattributeDialog, setOpenDeattributeDialog] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDriverModal, setOpenDriverModal] = useState(false)
  const [messageErreur, setMessageErreur] = useState('')
  const [filtredUnservedTours, setFiltredUnservedTours] = useState([])
  const [selectedFilter, setSelectedFilter] = useState(filterTab[0])
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openFilter = Boolean(anchorEl)
  const handleDragEnd = (result) => {
    const simulate = _.cloneDeep(planning)

    //mettre les items hors espace drag&drop
    if (!result.destination) {
      return
    }
    //mettre les item au debut de la tournée || au fin de la fin de la tournée
    if (
      (result.destination.index === result.source.index &&
        result.destination.droppableId == result.source.droppableId) ||
      (result.destination.droppableId !== '-1' && result.destination.index === 0) ||
      (result.destination.droppableId !== '-1' &&
        result.source.droppableId === '-1' &&
        result.destination.index ===
          simulate[parseInt(result.destination.droppableId)]?.markers?.length) ||
      (result.destination.droppableId !== '-1' &&
        result.source.droppableId !== '-1' &&
        result.destination.index ===
          simulate[parseInt(result.destination.droppableId)]?.markers?.length) ||
      (result.destination.droppableId === result.source.droppableId &&
        result.destination.index ===
          simulate[parseInt(result.destination.droppableId)]?.markers?.length - 1)
    ) {
      return
    }

    // drag and drop entre les tableaux de chauffeurs
    if (
      !(
        result.destination.droppableId === '-1' || result.source.droppableId === '-1'
      )
    ) {
      const { simTours, validator } = reorderTours(
        result,
        simulate,
        tourRecChecked,
        isOnePickUp
      )
      if (!validator) {
        setOpen(true)
        setMessageErreur(
          'Vous ne pouvez pas mettre pickup entre dropoff, Merci de vérifier.'
        )
      } else {
        if (result) {
          handleToursReOrder(
            simTours,
            fleet.deliveryDate,
            isOnePickUp,
            isOptimisationCoursesFiles,
            undefined,
            undefined
          )
        }
      }
      //drag and drop entre tableau chauffeur && tableau course
    } else if (
      (result.destination.droppableId === '-1' ||
        result.source.droppableId === '-1') &&
      result.destination.droppableId !== result.source.droppableId
    ) {
      const { simTours, isUnservedChanged, newUnservedTours, validator } =
        reorderFromUnserved(
          result,
          simulate,
          tourRecChecked,
          filtredUnservedTours,
          selectedCourses,
          handleCheck,
          isOnePickUp
        )
      if (!validator) {
        setOpen(true)
        setMessageErreur(
          'Vous avez choisis plusieurs courses avec different point de pickups, Merci de changer le type.'
        )
      } else {
        if (result) {
          handleToursReOrder(
            simTours,
            fleet.deliveryDate,
            isOnePickUp,
            isOptimisationCoursesFiles,
            isUnservedChanged,
            newUnservedTours
          )
        }
      }
    }
  }
  const generateTours = () => {
    const toursInfoPayload = getToursInfos(
      planning,
      isOnePickUp,
      isOptimisationCoursesFiles,
      null
    )
    createTours(toursInfoPayload)
  }
  const generateOptimizedTours = (driverList) => {
    const tours = planning.flatMap((tour) => tour.markers)
    if (
      !validateLocationOnePickUp(tours, unservedTours, selectedCourses, isOnePickUp)
    ) {
      setOpen(true)
      setMessageErreur(
        'Vous avez choisis plusieurs courses avec different point de pickups, Merci de changer le type.'
      )
    } else {
      const driverParse = driverList.map((el) => el.code)

      const checkedFleetList = {
        ...fleet,
        drivers: fleet?.drivers?.filter((e) => driverParse.includes(e.id)),
      }
      const tours = {
        courses: [
          ...getParsedCourses(planning, driverParse),
          ...getCheckedUnserved(unservedTours, selectedCourses),
        ].flat(),
        ...getFleetParse(
          checkedFleetList,
          getLocationFromUnservedCourses(unservedTours, selectedCourses),
          isOnePickUp
        ),
        isFile: isOptimisationCoursesFiles,
      }
      simulateTours(tours, isOnePickUp)
    }
  }

  const filterUnservedOrders = (orders, prevFilter) => {
    if (prevFilter?.label === filterTab[0].label) {
      return orders
    } else {
      return orders.filter((order) =>
        prevFilter.label == filterTab[2].label
          ? !ACCURATE_ADDRESS.includes(order.locationType)
          : selectedCourses?.includes(order.id)
      )
    }
  }

  useEffect(() => {
    setSelectedFilter((prevFilter) => {
      const newData = filterUnservedOrders(unservedTours, prevFilter)
      setFiltredUnservedTours(newData)
      return prevFilter
    })
  }, [selectedCourses, selectedFilter, unservedTours])

  const onChangeTourType = () => {
    const checked = existingPlanning(planning, isOnePickUp)
    if (checked != isOnePickUp) changeToursTypeState(checked)
  }

  const onCheckedType = () => {
    if (!locationCheck(unservedTours, selectedCourses, planning, isOnePickUp)) {
      onChangeTourType()
    } else {
      setOpen(true)
      setMessageErreur(
        'Vous avez choisis plusieurs courses avec different point de pickups, Merci de changer le type.'
      )
    }
  }
  useEffect(() => {
    return () => {
      resetCheckedAll()
    }
  }, [])

  //RESIZE
  const [expandVertical, setExpandVertical] = useState(false)
  const [expandHorizental, setExpandHorizental] = useState(false)
  //resize VERTICALLY

  const onExpend = (value) => setExpandVertical(value)
  const onExpendHorizental = (value) => setExpandHorizental(value)

  const onDeattribute = () => {
    const simulate = _.cloneDeep(planning)
    const { simTours, newUnservedTours } = reorderFromUnservedDeattribute(
      focusedTours,
      simulate,
      unservedTours
    )
    setOpenDeattributeDialog(false)
    handleToursReOrder(
      simTours,
      fleet.deliveryDate,
      isOnePickUp,
      isOptimisationCoursesFiles,
      true,
      newUnservedTours
    )
  }

  const getToursFocused = (simulate, focusedTours) => {
    return focusedTours.map((index) => simulate[index].driver.firstName)
  }

  const exportFilePlannification = (isDownload) => {
    const toursInfoPayload = getToursInfos(
      planning,
      isOnePickUp,
      isOptimisationCoursesFiles,
      isDownload
    )
    exportTours(toursInfoPayload, isDownload)
  }

  const disabledButtonAction = () => {
    return !planning?.some((tour) => tour.markers.length > 2)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getDriverWithTours = () => {
    const driverList = planning
      .filter((tour) => tour.markers.length > 2)
      .map((tour) => tour.driver.id)

    return driverList
  }
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage = 'Do you really want to leave?'

      event.returnValue = confirmationMessage
      return confirmationMessage
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const inversionTournee = () => {
    const simulate = _.cloneDeep(planning)
    const { simTours } = reorderFromUnservedInversionTournee(focusedTours, simulate)
    handleToursReOrder(
      simTours,
      fleet.deliveryDate,
      isOnePickUp,
      isOptimisationCoursesFiles,
      undefined,
      undefined
    )
  }

  const inversionOrderTournee = () => {
    const simulate = _.cloneDeep(planning)
    const { simTours } = reorderFromUnservedInversionOrderTournee(
      focusedTours,
      simulate
    )
    handleToursReOrder(
      simTours,
      fleet.deliveryDate,
      isOnePickUp,
      isOptimisationCoursesFiles,
      undefined,
      undefined
    )
  }

  const handleResetFilter = () => {
    setSelectedFilter(filterTab[0])
    fetchCourses({})
  }

  const reOptimizerTours = () => {
    const simulate = _.cloneDeep(planning)
    const driverParse = []
    focusedTours.map((pos) => {
      driverParse.push(simulate[pos].driver.id)
    })
    const checkedFleetList = {
      ...fleet,
      drivers: fleet?.drivers?.filter((e) => driverParse.includes(e.id)),
    }
    const tours = {
      courses: [...getParsedCourses(simulate, driverParse)].flat(),
      ...checkedFleetList,
      isFile: isOptimisationCoursesFiles,
    }

    simulateTours(tours, isOnePickUp)
  }
  //menu filtre tournee
  const [planningFiltered, setPlanningFiltered] = useState([])
  const [selectedFilterTournee, setSelectedFilterTournee] = useState(
    filterTournee[0]
  )

  const filterTournees = (planning, prevFilter) => {
    const planningClone = _.cloneDeep(
      planning.sort((a, b) => {
        if (a.driver?.firstName < b.driver?.firstName) {
          return -1
        }
        if (a.driver?.firstName > b.driver?.firstName) {
          return 1
        }
        return 0
      })
    )
    if (prevFilter?.code === filterTournee[0].code) {
      return planningClone
    } else if (prevFilter?.code === filterTournee[1].code) {
      return planningClone.filter((el, i) => focusedTours.includes(i))
    } else if (prevFilter?.code === filterTournee[2].code) {
      return planningClone.filter((el) => el.markers.length == 2)
    } else if (prevFilter?.code === filterTournee[3].code) {
      return planningClone.filter((el) => el.markers.length > 2)
    } else if (prevFilter?.code === filterTournee[4].code) {
      return planningClone.sort((a, b) => {
        if (a.driver?.firstName < b.driver?.firstName) {
          return -1
        }
        if (a.driver?.firstName > b.driver?.firstName) {
          return 1
        }
        return 0
      })
    } else if (prevFilter?.code === filterTournee[5].code) {
      return planningClone.sort((a, b) => {
        if (a.driver?.firstName < b.driver?.firstName) {
          return 1
        }
        if (a.driver?.firstName > b.driver?.firstName) {
          return -1
        }
        return 0
      })
    } else if (prevFilter?.code === filterTournee[6].code) {
      return planningClone.filter((a) => a.driver?.name == 'STT')
    }
    return planningClone
  }

  useEffect(() => {
    setSelectedFilterTournee((prevFilter) => {
      const newData = filterTournees(planning, prevFilter)
      setPlanningFiltered(newData)
      return prevFilter
    })
  }, [focusedTours, selectedFilterTournee, planning])

  const getDriversOptimiser = () => {
    let drivers = []
    const driverParse = []
    focusedTours.map((pos) => {
      driverParse.push(planning[pos].driver.id)
    })

    drivers = fleet?.drivers
      .filter((driver) => {
        return (
          (driverParse.length > 0 &&
            driverParse.includes(driver.id) &&
            !planning.find((plan) => plan.driver.id === driver.id)?.lock) ||
          (!driverParse.includes(driver.id) &&
            !planning.find((plan) => plan.driver.id === driver.id)?.lock &&
            (driverParse.length == 0 ||
              driverParse.every(
                (driver) => planning.find((plan) => plan.driver.id === driver)?.lock
              )))
        )
      })
      .sort((a, b) => {
        if (a?.firstName < b?.firstName) {
          return -1
        }
        if (a?.firstName > b?.firstName) {
          return 1
        }
        return 0
      })

    return drivers
  }

  //placement de courses
  const posTournee = (tour) => {
    return planning.map((el) => el.driver.id).indexOf(tour.driver.id)
  }

  const posMarkerDestination = (tourDestination, postionLet) => {
    if (tourDestination.markers.length == 2) {
      return 1
    } else {
      const markesDropOff = tourDestination.markers.filter((item) =>
        isOptimisationCoursesFiles ? item.type != 'pickup' : item
      )

      return tourDestination.markers.findIndex(
        (marker) =>
          marker.location_id ==
            markesDropOff[postionLet === 'debut' ? 1 : markesDropOff.length - 2]
              .location_id &&
          ((marker.type == 'dropoff' && isOptimisationCoursesFiles) ||
            (marker.type ==
              markesDropOff[postionLet === 'debut' ? 1 : markesDropOff.length - 2]
                .type &&
              !isOptimisationCoursesFiles))
      )
    }
  }
  const posMarkerSource = (tourSource) => {
    return tourSource.markers.findIndex((marker) =>
      Object.keys(tourRecChecked).includes(marker.receptionNumber)
    )
  }
  const posUnservedSource = () => {
    return unservedTours.findIndex(({ id }) => selectedCourses.includes(id))
  }

  //move entre table tournee
  const moveTableTournee = (tourSource, tourDestination, postionLet) => {
    const result = {
      source: {
        droppableId: String(posTournee(tourSource)),
        index: posMarkerSource(tourSource),
      },
      destination: {
        droppableId: String(posTournee(tourDestination)),
        index:
          postionLet === 'fin' &&
          tourSource != tourDestination &&
          tourDestination.markers.length > 2
            ? posMarkerDestination(tourDestination, postionLet) + 1
            : posMarkerDestination(tourDestination, postionLet),
      },
    }
    handleDragEnd(result)
  }
  //move table tournee to unserved
  const deattributeCourse = (tourSource) => {
    const result = {
      source: {
        droppableId: String(posTournee(tourSource)),
        index: posMarkerSource(tourSource),
      },
      destination: { droppableId: '-1', index: 0 },
    }
    handleDragEnd(result)
  }
  //move table unserved to tournee
  const moveTableUnserved = (tourDestination, postionLet) => {
    const result = {
      source: { droppableId: '-1', index: posUnservedSource() },
      destination: {
        droppableId: String(posTournee(tourDestination)),
        index:
          postionLet === 'fin' && tourDestination.markers.length > 2
            ? posMarkerDestination(tourDestination, postionLet) + 1
            : posMarkerDestination(tourDestination, postionLet),
      },
    }
    handleDragEnd(result)
  }

  return (
    <>
      <Prompt when={true} message={MESSAGE_CONFIRMATION_POLARIS} />
      {(isSimError || isErrorAffectPlanning || open) && (
        <ModalError
          open={open || isSimError || isErrorAffectPlanning}
          handleClose={() => {
            if (open) {
              setOpen(false)
              setMessageErreur('')
            } else {
              removeError(isSimError)
            }
          }}
          message={messageErreur || simulationErrorMessage.message}
          messageLink={simulationErrorMessage.editUrl}
        />
      )}

      <Backdrop
        sx={(theme) => ({
          color: '#1976D2',
          zIndex: theme.zIndex.drawer + 100,
          background: '#f5f5f5',
        })}
        open={isSimLoading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        <div style={{ marginLeft: '10px', color: '#1976D2', fontSize: '16px' }}>
          {' '}
          Veuillez patienter la génération de courses est en cours
        </div>
      </Backdrop>
      {!isErrorAffectPlanning && (
        <>
          <div
            style={{
              width: '100%',
              marginTop: '15px',
              height: 'calc(100vh - 120px)',
              overflow: 'unset',
            }}
          >
            {/* Add Steper and get date index */}

            <div
              style={{
                width: '100%',
                height: '100%',
                overflow: 'unset',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: expandHorizental ? '46px' : '65%',
                  background: 'rgb(245 245 245)',
                }}
              >
                <TopContainer>
                  <DetailTounerContainer
                    expandVertical={expandVertical}
                    expandHorizental={expandHorizental}
                  >
                    <ToursDetails
                      onChecked={onCheckedType}
                      checked={isOnePickUp}
                      setOpenDeattributeDialog={setOpenDeattributeDialog}
                      isOptimisationCoursesFiles={isOptimisationCoursesFiles}
                      inversionTournee={inversionTournee}
                      expand={expandVertical}
                      onExpend={onExpend}
                      inversionOrderTournee={inversionOrderTournee}
                      reOptimizerTours={reOptimizerTours}
                      handleDragEnd={handleDragEnd}
                      //menu filtre tournee
                      filterTournee={filterTournee}
                      planningFiltered={planningFiltered}
                      setSelectedFilterTournee={setSelectedFilterTournee}
                      selectedFilterTournee={selectedFilterTournee}
                      //placement de courses
                      posTournee={posTournee}
                      moveTableTournee={moveTableTournee}
                      deattributeCourse={deattributeCourse}
                    />
                  </DetailTounerContainer>
                  <MapProgrammer
                    isSimLoading={isSimLoading}
                    planning={planning}
                    unservedTours={filtredUnservedTours}
                    isOptimisationCoursesFiles={isOptimisationCoursesFiles}
                    displayNone={expandVertical && !expandHorizental}
                  />
                </TopContainer>
              </div>

              <div
                style={{
                  width: '100%',
                  height: expandHorizental ? 'calc(100% - 46px)' : '35%',
                }}
              >
                <div
                  style={{
                    color: '#fff',
                    display: 'inline-flex',
                    alignItems: 'center',
                    background: '#1976d2',
                    height: '45px',
                    width: '100%',
                    padding: '0 10px',
                    justifyContent: 'space-between',
                    position: 'sticky',
                    top: 0,
                    zIndex: '100',
                  }}
                >
                  <Button
                    id="basic-button"
                    aria-controls={openFilter ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openFilter ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{ color: 'white!important', textTransform: 'none' }}
                    disabled={!isOptimisationCoursesFiles}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {selectedFilter?.label}
                      {isOptimisationCoursesFiles && (
                        <FilterIcon
                          style={{
                            marginLeft: '5px',
                            width: '18px',
                            height: '18px',
                          }}
                        />
                      )}
                    </div>
                  </Button>
                  <div>
                    {(Object.keys(filtersValues).length > 0 ||
                      selectedFilter != filterTab[0]) && (
                      <Button
                        style={{
                          background: 'white',
                          color: '#1976D2',
                          marginRight: '10px',
                        }}
                        onClick={() => handleResetFilter()}
                      >
                        Réinitialiser le filtre
                      </Button>
                    )}
                    <FontAwesomeIcon
                      style={{ cursor: 'pointer' }}
                      icon={expandHorizental ? faCompressAlt : faExpandAlt}
                      onClick={() => onExpendHorizental(!expandHorizental)}
                    />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openFilter}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {filterTab.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            handleClose()
                            setSelectedFilter(item)
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      )
                    })}
                  </Menu>
                </div>
                <UnservedOrders
                  unservedOrders={filtredUnservedTours}
                  isOptimisationCoursesFiles={isOptimisationCoursesFiles}
                  filtersValues={filtersValues}
                  setFilters={fetchCourses}
                  //placement de courses
                  moveTableUnserved={moveTableUnserved}
                  planningFiltered={planningFiltered}
                />
              </div>
            </div>

            <div
              style={{
                width: '100%',
                height: '50px',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 20px',
                backgroundColor: 'white',
                position: 'fixed',
                bottom: '0px',
                zIndex: '100',
                alignItems: 'center',
                boxShadow:
                  ' 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
                overflow: 'auto',
              }}
            >
              <div>
                <Button
                  onClick={(event) => {
                    const confirmLeave = window.confirm(MESSAGE_CONFIRMATION_POLARIS)
                    if (confirmLeave) {
                      viewPlannification(false)
                      activeTourAction()
                      event.stopPropagation()
                    }
                  }}
                  sx={{
                    '&.MuiButton-root': {
                      width: '113px',
                      marginRight: '20px !important',
                      color: '#fff !important',
                      backgroundColor: '#1976D2 !important',
                      textTransform: 'capitalize !important',
                      fontSize: '13px !important',
                      '&:hover': {
                        backgroundColor: 'rgb(17, 82, 147) !important',
                      },
                    },
                    '&.Mui-disabled': {
                      color: 'rgba(0, 0, 0, 0.26) !important',
                      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                    },
                  }}
                  id={'boutton-sauvgarder-page-planification'}
                >
                  Retour
                </Button>

                {isOptimisationCoursesFiles && (
                  <Button
                    disabled={disabledButtonAction()}
                    onClick={(event) => {
                      exportFilePlannification(true)
                      event.stopPropagation()
                    }}
                    sx={{
                      '&.MuiButton-root': {
                        width: '113px',
                        color: '#fff !important',
                        textTransform: 'capitalize !important',
                        fontSize: '13px !important',
                        backgroundColor: '#10A549',
                        ':hover': {
                          backgroundColor: '#158f44',
                        },
                      },
                      '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important',
                        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                      },
                    }}
                    id={'button-exporter-page-planification'}
                  >
                    <Download /> Sauvegarder
                  </Button>
                )}
              </div>
              <div style={{ display: 'flex', marginLeft: '5px' }}>
                <Button
                  onClick={(event) => {
                    const drivers = [...getDriversOptimiser()].map((el) => {
                      return {
                        code: el.id,
                      }
                    })
                    if (drivers.length == 1 && focusedTours.length == 1) {
                      generateOptimizedTours(drivers)
                    } else {
                      setOpenDriverModal(true)
                    }
                    event.stopPropagation()
                  }}
                  disabled={
                    selectedCourses.length <= 0 ||
                    (isOptimisationCoursesFiles &&
                      filtredUnservedTours.some(
                        (course) =>
                          selectedCourses.includes(course.id) &&
                          !ACCURATE_ADDRESS.includes(course.locationType)
                      ))
                  }
                  sx={{
                    '&.MuiButton-root': {
                      marginRight: '20px !important',
                      width: '113px',
                      textTransform: 'none ',
                      fontSize: '13px !important',
                      backgroundColor: 'white !important',
                      color: '#10A549',
                      border: '1px solid #10A549',
                      ':hover': {
                        backgroundColor: 'white',
                        border: '1px solid #158f44',
                        color: '#158f44',
                      },
                    },
                    '&.Mui-disabled': {
                      color: 'rgba(0, 0, 0, 0.26) !important',
                      backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                    },
                  }}
                  id={'button-optimiser-page-planification'}
                >
                  Optimiser
                </Button>
                {isOptimisationCoursesFiles ? (
                  <Button
                    disabled={disabledButtonAction()}
                    onClick={(event) => {
                      exportFilePlannification()
                      event.stopPropagation()
                    }}
                    sx={{
                      '&.MuiButton-root': {
                        width: '113px',
                        color: '#fff !important',
                        textTransform: 'capitalize !important',
                        fontSize: '13px !important',
                        backgroundColor: '#10A549',
                        ':hover': {
                          backgroundColor: '#158f44',
                        },
                      },
                      '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important',
                        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                      },
                    }}
                    id={'button-exporter-page-planification'}
                  >
                    Exporter
                  </Button>
                ) : (
                  <Button
                    onClick={(event) => {
                      generateTours()
                      event.stopPropagation()
                    }}
                    sx={{
                      '&.MuiButton-root': {
                        marginRight: '20px !important',
                        width: '113px',
                        color: '#fff !important',
                        textTransform: 'capitalize !important',
                        fontSize: '13px !important',
                        backgroundColor: '#10A549',
                        ':hover': {
                          backgroundColor: '#158f44',
                        },
                      },
                      '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important',
                        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                      },
                    }}
                    id={'button-sauvgarder-page-planification'}
                    disabled={!planning.some(({ markers }) => markers.length > 2)}
                  >
                    Sauvegarder
                  </Button>
                )}
              </div>
            </div>
          </div>
          {openDriverModal ? (
            <DriverModal
              openDriverModal={openDriverModal}
              setOpenDriverModal={setOpenDriverModal}
              generateOptimizedTours={generateOptimizedTours}
              drivers={[...getDriversOptimiser()]}
              notEmptyTours={getDriverWithTours()}
            />
          ) : null}
        </>
      )}
      {openDeattributeDialog && (
        <Dialog
          maxWidthDialog={'md'}
          open={openDeattributeDialog}
          title={'Désattribuer tournée'}
          style={isSimError || isErrorAffectPlanning ? { display: 'none' } : {}}
          iconTitle={
            <IconDistribuer
              style={{
                width: '20px',
                height: '20px',
                marginRight: '9px',
              }}
            />
          }
          content={
            <div style={{ padding: '30px' }}>
              {`Voulez vous vraiment désattribuer ${
                getToursFocused(planning, focusedTours).length == 1
                  ? 'la tournée'
                  : 'les tournées'
              }? "${getToursFocused(planning, focusedTours).join(', ')}"`}
            </div>
          }
          handleClose={() => {
            setOpenDeattributeDialog(false)
          }}
          handleClickAction={() => {
            onDeattribute()
          }}
        />
      )}
    </>
  )
}
Plannification.propTypes = {
  unservedTours: PropTypes.any,
  /* reorder tours function */
  handleToursReOrder: PropTypes.func,
  /* Planning data */
  planning: PropTypes.any,
  /* Planning data coming from the store */
  fleet: PropTypes.any,
  /* optimization action */
  simulateTours: PropTypes.func,
  createTours: PropTypes.func,
  viewPlannification: PropTypes.func,
  handleCheckAll: PropTypes.func,
  handleCheck: PropTypes.func,
  resetCheckedAll: PropTypes.func,
  changeToursTypeState: PropTypes.func,
  isOnePickUp: PropTypes.any,
  isSimLoading: PropTypes.bool,
  isSimError: PropTypes.bool,
  simulationErrorMessage: PropTypes.object,
  removeError: PropTypes.func,
  isErrorAffectPlanning: PropTypes.bool,
  activeTourAction: PropTypes.func,
  hideImpossibleCourses: PropTypes.any,
  setHideImpossibleCourses: PropTypes.func,
  planification: PropTypes.bool,
  isOptimisationCoursesFiles: PropTypes.bool,
  exportTours: PropTypes.func,
  filtersValues: PropTypes.object,
  fetchCourses: PropTypes.func,
  selectedCourses: PropTypes.array,
  focusedTours: PropTypes.array,
  tourRecChecked: PropTypes.object,
}
